import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  useTheme,
  Box,
} from "@mui/material";

import { FcPuzzle } from "react-icons/fc";
import { centsToLocalString } from "../../shared/money";
export default function AdditionalServicesTable({ reservationServices }) {
  const theme = useTheme();
  return (
    <Box
      sx={{ boxShadow: theme.shadows[1], borderRadius: "15px", padding: "5px" }}
    >
      <Box
        sx={{
          height: "600px",
          overflow: "auto",
        }}
      >
        <TableContainer sx={{ overflowX: "initial" }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography
                    style={{
                      fontFamily: "Sofia Pro",
                      fontSize: 22,
                      color: "rgba(0, 0, 0, 0.6)",
                      padding: 5,
                    }}
                  >
                    Lisäpalvelut
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ color: "#acb9c9", fontSize: "12px" }}
                  align="left"
                >
                  <Typography variant="caption">NIMI</Typography>
                </TableCell>
                <TableCell
                  sx={{ color: "#acb9c9", fontSize: "12px" }}
                  align="center"
                  style={{ paddingTop: 10 }}
                >
                  <Typography variant="caption">KPL</Typography>
                </TableCell>
                <TableCell
                  sx={{ color: "#acb9c9", fontSize: "12px" }}
                  align="center"
                  style={{ paddingTop: 10 }}
                >
                  <Typography variant="caption">MYYNTI</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservationServices.map((item) => {
                if (item?.isRemoved) return null;
                return (
                  <TableRow key={item.key + 10000}>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <FcPuzzle
                          style={{
                            fontSize: 40,
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                            border: "solid 1px lightgrey",
                            padding: 4,
                            borderRadius: 10,
                            marginRight: 10,
                          }}
                        />

                        <Typography variant="body1">
                          {item.description}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2">{item.quantity}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body2"
                        style={{
                          fontWeight: "bold",
                          color: "#36454F",
                        }}
                      >
                        {centsToLocalString(item.total, true)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
