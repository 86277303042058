import {
  Grid,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";

import { centsToLocalString, parseToCents } from "../../../shared/money";
import {
  calculateEffectivePrices,
  parsePricingElements,
} from "../../../shared/pricing-utilities";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNotification } from "../../Notification";
import { AppContext } from "../../../AppContext";
import { captureError } from "../../../utilities";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { useHandlePricing } from "../hooks/useHandlePricing";
import { useFetchPricingsOrganization } from "../../../services/fetchPricings";
import { getVatConfig } from "~/configs/vat-values";

function calculatePrices(priceStrings, defaultStartPrice) {
  const prices = priceStrings.map(parseToCents);
  return calculateEffectivePrices(prices, defaultStartPrice);
}

export default function EditPricingDialog({ onClose, pricing, disabled }) {
  const { VAT_VALUES, DEFAULT_VAT_VALUE } = getVatConfig();
  const [submitting, setSubmitting] = useState(false);
  const [useDifferentTaxRate, setUseDifferentTaxRate] = useState(true);
  const [taxRate, setTaxRate] = useState(pricing?.tax ?? DEFAULT_VAT_VALUE);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const initialPricingSheet = useMemo(() => {
    const pricingSheet = parsePricingElements(pricing?.elements);
    const format = (price) => (price != null ? centsToLocalString(price) : "");

    return {
      hourlyRates: pricingSheet.hourlyRates.map(format),
      dailyRates: pricingSheet.dailyRates.map(format),
    };
  }, [pricing]);

  const [name, setName] = useState(pricing?.name);
  const [deductible, setDeductible] = useState(
    centsToLocalString(pricing?.deductible) || ""
  );
  const [submitted, setSubmitted] = useState(false);
  const [hourlyRates, setHourlyRates] = useState(
    initialPricingSheet.hourlyRates
  );
  const [dailyRates, setDailyRates] = useState(initialPricingSheet.dailyRates);
  const [useNextDayPrice, setUseNextDayPrice] = useState(
    pricing?.useNextDayPrice
  );
  const { group, user, business } = useContext(AppContext);

  const effectiveHourlyRates = useMemo(
    () => calculatePrices(hourlyRates),
    [hourlyRates]
  );
  const effectiveDailyRates = useMemo(
    () =>
      calculatePrices(
        dailyRates,
        effectiveHourlyRates[22].total +
          effectiveHourlyRates[22].currentEffectiveRate
      ),
    [dailyRates, effectiveHourlyRates]
  );

  // Organization owned pricings may include Org wide online pricing and ExternalBusiness pricings
  const isOrgOwnedPricing = useMemo(() => {
    const { organizationId, orgBusinessId } = pricing;
    //  Existing org owned pricing or org admin creating new org pricing
    return (
      (organizationId && !orgBusinessId) ||
      (user?.role === "ORGANIZATION_ADMIN" && !business?.id)
    );
  }, [pricing]);

  useEffect(() => {
    if (
      pricing?.tax === DEFAULT_VAT_VALUE ||
      isNaN(pricing?.tax) ||
      pricing?.tax === null
    ) {
      setUseDifferentTaxRate(false);
    }
  }, [pricing?.tax]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Dialog open fullWidth={true} maxWidth={"md"} fullScreen={fullScreen}>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sx={{ marginTop: "25px" }}>
              <TextField
                placeholder="Nimi"
                label="Nimi"
                value={name}
                disabled={disabled}
                onChange={(evt) => {
                  setSubmitted(false);
                  setName(evt.target.value);
                }}
                required
                error={submitted && !name}
                helperText={submitted && !name && "Täytä tämä kenttä"}
                data-cy="pricingDialogNameField"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="useNextDayPrice"
                    checked={useNextDayPrice}
                    disabled={disabled}
                    color="primary"
                    onChange={(e) => {
                      setUseNextDayPrice(e.target.checked);
                    }}
                  />
                }
                label={"Yli 24h varauksissa seuraavan täyden vuorokauden hinta"}
                data-cy="pricingDialogNextDayPriceCheckbox"
              />
            </Grid>
            <Grid
              style={{
                paddingTop: 0,
                marginTop: 0,
                paddingBottom: 0,
                marginBottom: 0,
                alignItems: "center",
                display: "flex",
              }}
              item
              xs={12}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="useDifferentTaxRate"
                    checked={useDifferentTaxRate}
                    disabled={disabled}
                    color="primary"
                    onChange={(e) => {
                      setUseDifferentTaxRate(e.target.checked);
                    }}
                    data-cy="pricingDialogTaxRateCheckbox"
                  />
                }
                label={`Käytä muuta kuin ${VAT_VALUES[DEFAULT_VAT_VALUE]}% veroa`}
              />
            </Grid>
            {useDifferentTaxRate ? (
              <Grid item style={{ paddingTop: 0, marginTop: 0 }} xs={12}>
                <Typography variant="h6">ALV %</Typography>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  style={{ minWidth: 200 }}
                  disabled={disabled}
                  size="small"
                  placeholder="24"
                  value={taxRate}
                  onChange={(evt) => {
                    setSubmitted(false);
                    setTaxRate(evt.target.value);
                  }}
                  error={submitted && useDifferentTaxRate && !taxRate}
                  data-cy="pricingDialogTaxRateSelect"
                >
                  {Object.entries(VAT_VALUES).map(([value, label]) => (
                    <MenuItem
                      key={value}
                      value={value}
                      data-cy="pricingDialogTaxRateOption"
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : null}
            {isOrgOwnedPricing && (
              <Grid item xs={12} style={{ paddingTop: 0, marginTop: 15 }}>
                <TextField
                  size="small"
                  placeholder="Omavastuu"
                  label="Omavastuu"
                  value={deductible}
                  disabled={disabled}
                  onChange={(evt) => {
                    let value = evt.target.value;
                    // Verify that inputted value is numerical with max one ",". E.g 10,50
                    if ((value.match(/,/g) || []).length <= 1) {
                      const numValue = value.replace(",", ".");
                      if (isNaN(numValue)) {
                        return;
                      }
                    } else {
                      return;
                    }
                    setSubmitted(false);
                    setDeductible(evt.target.value);
                  }}
                  error={submitted && !parseToCents(deductible)}
                  helperText={submitted && !parseToCents(deductible)}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tunti</TableCell>
                    <TableCell>Tuntihinta</TableCell>
                    <TableCell align="right">Kokonais&shy;hinta</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hourlyRates.map((hourlyRate, index) => {
                    const rateInfo = effectiveHourlyRates[index];
                    const isExtraHour = index === hourlyRates.length - 1;

                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {isExtraHour ? "Lisätunti" : index + 1 + "."}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            size="small"
                            placeholder={centsToLocalString(
                              rateInfo.currentEffectiveRate
                            )}
                            disabled={disabled}
                            value={hourlyRate}
                            onChange={(evt) => {
                              let value = evt.target.value;

                              // Verify that inputted value is numerical with max one ",". E.g 10,50
                              if ((value.match(/,/g) || []).length <= 1) {
                                const numValue = value.replace(",", ".");
                                if (isNaN(numValue)) {
                                  return;
                                }
                              } else {
                                return;
                              }

                              const newRates = [...hourlyRates];
                              newRates[index] = value;
                              setHourlyRates(newRates);
                            }}
                            data-cy="pricingDialogHourlyPriceField"
                          />
                        </TableCell>
                        <TableCell align="right">
                          {isExtraHour
                            ? "-"
                            : centsToLocalString(rateInfo.total)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Päivä</TableCell>
                    <TableCell>Päivähinta</TableCell>
                    <TableCell align="right">Kokonais&shy;hinta</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dailyRates.map((dailyRate, index) => {
                    const rateInfo = effectiveDailyRates[index];
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {index + 1 + "."}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            size="small"
                            placeholder={centsToLocalString(
                              rateInfo.currentEffectiveRate
                            )}
                            value={dailyRate}
                            disabled={disabled}
                            onChange={(evt) => {
                              let value = evt.target.value;
                              // Verify that inputted value is numerical with max one ",". E.g 10,50
                              if ((value.match(/,/g) || []).length <= 1) {
                                const numValue = value.replace(",", ".");
                                if (isNaN(numValue)) {
                                  return;
                                }
                              } else {
                                return;
                              }

                              const newRates = [...dailyRates];
                              newRates[index] = value;
                              setDailyRates(newRates);
                            }}
                            data-cy="pricingDialogDailyPriceField"
                          />
                        </TableCell>
                        <TableCell align="right">
                          {centsToLocalString(rateInfo.total)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDeleteConfirmation(true)}
            variant="contained"
            color="error"
          >
            Poista
          </Button>
          <Box flex={1} />
          <Button onClick={() => onClose(true)}>Sulje</Button>
          <SaveButton
            onClose={onClose}
            pricing={pricing}
            submitting={submitting}
            setSubmitting={setSubmitting}
            name={name}
            deductible={deductible}
            taxRate={taxRate}
            useDifferentTaxRate={useDifferentTaxRate}
            hourlyRates={hourlyRates}
            dailyRates={dailyRates}
            useNextDayPrice={useNextDayPrice}
            group={group}
            user={user}
            business={business}
            isOrgOwnedPricing={isOrgOwnedPricing}
          />
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog
        open={showDeleteConfirmation}
        pricing={pricing}
        onClose={() => setShowDeleteConfirmation(false)}
        onCloseDeleted={() => {
          setShowDeleteConfirmation(false);
          onClose();
        }}
      />
    </>
  );
}

const SaveButton = ({
  onClose,
  pricing,
  submitting,
  setSubmitting,
  name,
  deductible,
  taxRate,
  useDifferentTaxRate,
  hourlyRates,
  dailyRates,
  useNextDayPrice,
  group,
  user,
  business,
  isOrgOwnedPricing,
}) => {
  const { DEFAULT_VAT_VALUE } = getVatConfig();
  const notification = useNotification();
  const { handleCreate, handleUpdate } = useHandlePricing();
  const tax = !useDifferentTaxRate ? DEFAULT_VAT_VALUE : taxRate;
  const { pricingsMutate } = useFetchPricingsOrganization("pricingListDialog");
  const newPricing = {
    name,
    tax,
    businessCategoryAssociation:
      pricing?.businessCategoryAssociation ?? undefined,
    elements: [
      ...hourlyRates.map((r, index) => ({
        duration: "h" + index,
        price: parseToCents(r),
      })),
      ...dailyRates.map((r, index) => ({
        duration: "d" + index,
        price: parseToCents(r),
      })),
    ].filter((element) => element.price != null),
    useNextDayPrice,
  };

  if (group) {
    newPricing.group = group;
  }
  // TODO (CU-2g00z7z): Support editing Pricings from a Business as a ORG ADMIN.
  // Now hard coded so that org admin always edits org level pricings, idea is
  // that in the future if org admin has selected a business, for example pricings
  // are created in that business.
  if (user?.role === "ORGANIZATION_ADMIN" && !business?.id) {
    newPricing.organizationId = user.organizationId;
    if (isOrgOwnedPricing) {
      newPricing.deductible = parseToCents(deductible);
    }
  }
  if (business?.id) {
    newPricing.organizationId = user.organizationId;
    newPricing.orgBusinessId = business.id;
  }

  return (
    <Button
      disabled={submitting}
      onClick={async () => {
        if (!name || (!taxRate && useDifferentTaxRate)) {
          return;
        }
        setSubmitting(true);

        if (pricing?.id) {
          try {
            const response = await handleUpdate(pricing.id, newPricing);

            if (response) {
              pricingsMutate(response, {
                optimisticData: (data) => {
                  const updated = data.map((item) => {
                    if (item.id === pricing.id) {
                      return response;
                    } else {
                      return item;
                    }
                  });
                  return updated;
                },
                rollbackOnError: true,
                populateCache: false,
                revalidate: false,
              });
              onClose(true);
              notification.show(
                `Hinnaston ${
                  response?.result?.name ?? ""
                } muutokset tallennettu`,
                "success"
              );
            } else {
              notification.show("Jokin meni vikaan");
            }
          } catch (e) {
            captureError("Upsert pricing failed", "UPSERT_PRICING_FAILED", e);
            notification.show("Varauksen tallennus epäonnistui.");
          } finally {
            setSubmitting(false);
          }
        } else {
          try {
            const response = await handleCreate(newPricing);
            if (response) {
              console.log("result", response);
              pricingsMutate();
              onClose(true);
              notification.show(
                `Hinnasto ${response?.result?.name ?? ""} luotu`,
                "success"
              );
            }
          } catch (e) {
            captureError("Create pricing failed", "UPSERT_PRICING_FAILED", e);
            notification.show("Varauksen luominen epäonnistui.");
          } finally {
            setSubmitting(false);
          }
        }
      }}
      variant="contained"
    >
      Tallenna
    </Button>
  );
};
