import {
  Box,
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField,
  AvatarGroup,
  InputLabel,
  FormControl,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import React, { useContext } from "react";

import CustomerInformation from "../common/CustomerInformation";

import InsuranceInfo from "./InsuranceInfo";
import InsuranceAdjuster from "./InsuranceAdjuster";
import { AppContext } from "../../../AppContext";
import ProductImage from "../../ProductImage";
import SearchByPostalCode from "./PostalCodeSearch";
import ServiceProviderCard from "./ServiceProviderCard";
import { FormGrid, FormContent } from "../../common/FormComponents";
import { useFormikContext } from "formik";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { centsToLocalString } from "../../../shared/money";
import { useMemo } from "react";
import { getEffectiveVehiclePrice } from "../common/utils";
import { useReservationContext } from "../ReservationContext";
import ExternalDataList from "../../ExternalDataList";

export default function InsuranceClaimDialog(props) {
  const { visibility } = props;
  const { USER_ADMIN_INSURANCE } = useContext(AppContext);
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext();
  const { reservationData, vehicleClasses } = useReservationContext();
  // Control the content with this state,
  //
  const formType = useMemo(() => {
    if (!reservationData?.reservationVehicles) {
      return "insuranceClaim";
    } else {
      return "settledClaim";
    }
  }, [reservationData?.reservationVehicles]);
  if (visibility)
    return (
      <Grid
        container
        sx={{
          flexDirection: "column",
          padding: "10px",
          justifyContent: "flex-start",
          marginTop: "15px",
        }}
      >
        <Grid
          item
          sm={12}
          container
          sx={{ justifyContent: "flex-start", flexDirection: "row" }}
          spacing={1}
        >
          {/* add container for full heigth  */}
          {formType === "settledClaim" && (
            <Grid item xs={12}>
              <FormContent
                title={"Varaus tapahtuman tiedot"}
                visibility={USER_ADMIN_INSURANCE}
                expanded={true}
              >
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  alignItems={"flex-start"}
                >
                  <Grid item xs={10} sm={5} container direction={"column"}>
                    <Typography variant="caption">
                      Varauksen vuokraamo{" "}
                    </Typography>
                    <Divider sx={{ marginBottom: "10px" }} />

                    <ServiceProviderCard formType={formType} />
                  </Grid>
                  <Grid item xs={10} sm={5} container direction={"column"}>
                    <Typography variant="caption">
                      Varauksen ajankohta{" "}
                    </Typography>
                    <Divider sx={{ marginBottom: "10px" }} />

                    <Grid item>
                      <Typography sx={{ fontWeight: 600 }}>
                        Aloitusaika:{" "}
                        {reservationData?.startTime && (
                          <span style={{ fontWeight: 500 }}>
                            {format(
                              new Date(reservationData?.startTime),
                              "dd.MM.yyyy HH:mm",
                              {
                                locale: fi,
                              }
                            )}
                          </span>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ fontWeight: 600 }}>
                        Palautusaika:{" "}
                        {reservationData?.returnTime && (
                          <span style={{ fontWeight: 500 }}>
                            {format(
                              new Date(reservationData?.returnTime),
                              "dd.MM.yyyy HH:mm",
                              {
                                locale: fi,
                              }
                            )}
                          </span>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  {values?.reservationVehicles.length > 0 && (
                    <Grid item xs={10}>
                      <Typography variant="caption">
                        Varauksen tuotteet{" "}
                      </Typography>
                      <Divider sx={{ marginBottom: "10px" }} />

                      <ReservationVehicleCard r={values.reservationVehicles} />
                      <Divider sx={{ marginBottom: "10px" }} />
                    </Grid>
                  )}
                </Grid>
              </FormContent>
            </Grid>
          )}

          <Grid item xs={6}>
            {formType === "insuranceClaim" && (
              <>
                <FormContent
                  title={"Palvelun tarjoaja"}
                  visibility={USER_ADMIN_INSURANCE}
                  expanded={true}
                >
                  <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                    <FormGrid>
                      {!values?.orgBusinessId && (
                        <SearchByPostalCode formType={formType} />
                      )}
                      {values?.orgBusinessId && (
                        <ServiceProviderCard formType={formType} />
                      )}
                    </FormGrid>
                    <FormGrid sx={{ marginBottom: "10px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="replacement-vehicle-class">
                          Korvattava autoluokka
                        </InputLabel>

                        <Select
                          value={
                            values?.insuranceData?.replacementVehicleClass
                              ? values?.insuranceData?.replacementVehicleClass
                              : ""
                          }
                          onChange={handleChange}
                          label="Korvattava autoluokka"
                          name="insuranceData.replacementVehicleClass"
                          id="insuranceData.replacementVehicleClass"
                          error={
                            touched?.insuranceData?.replacementVehicleClass &&
                            !!errors?.replacementVehicleClass
                          }
                          data-cy="reservationDialogVehicleClassSelect"
                        >
                          {vehicleClasses.map((c) => (
                            <MenuItem
                              key={c.type}
                              value={c.type}
                              data-cy="reservationDialogVehicleClassOption"
                            >
                              {c.type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGrid>
                  </Grid>
                </FormContent>
              </>
            )}
            <CustomerInformation formType={formType} />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12} sm={6} md={12} container>
              <FormContent
                title={"Vakuutus"}
                visibility={USER_ADMIN_INSURANCE}
                expanded={true}
              >
                <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                  <InsuranceInfo />
                  <InsuranceAdjuster />
                  <FormGrid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="insuranceData.vatDeductible"
                          checked={
                            values?.insuranceData?.vatDeductible ?? false
                          }
                          color="primary"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label={<>ALV-vähennys</>}
                    />
                  </FormGrid>
                  <FormGrid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="insuranceData.urgent"
                          checked={values.insuranceData?.urgent ?? false}
                          color="primary"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label={<>Kiireellinen</>}
                    />
                  </FormGrid>
                </Grid>
              </FormContent>
              <Grid item xs={12}>
                <FormContent
                  title={"Lisätietoja"}
                  visibility={USER_ADMIN_INSURANCE}
                  expanded={true}
                >
                  <FormGrid>
                    <TextField
                      value={values.internalNotes ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="internalNotes"
                      label="Lisätiedot"
                      variant="outlined"
                      error={touched.internalNotes && !!errors.internalNotes}
                      helperText={touched.internalNotes && errors.internalNotes}
                      fullWidth
                      multiline={true}
                      rows={2}
                    />
                  </FormGrid>
                </FormContent>
              </Grid>
            </Grid>
          </Grid>
          <ExternalDataList />
        </Grid>
      </Grid>
    );
}

function ReservationVehicleCard({ r, idx }) {
  const { values } = useFormikContext();
  const totalPrice = useMemo(() => {
    let effectiveVehicleTotalPrice = 0;
    for (let id of values.vehicleIds) {
      const effectiveVehiclePrice = getEffectiveVehiclePrice(values, null, id);
      if (effectiveVehiclePrice === null) {
        return null;
      }
      effectiveVehicleTotalPrice += effectiveVehiclePrice;

      return effectiveVehicleTotalPrice;
    }
  }, [values]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <AvatarGroup max={2} sx={{ marginRight: "5px" }}>
        {r.map((vehicle, index) => {
          return (
            <ProductImage
              key={`product-image-key-${index}`}
              vehicleId={vehicle?.id}
            />
          );
        })}
      </AvatarGroup>
      <Grid container direction={"row"} sx={{ alignItems: "center" }}>
        {r.map((vehicle) => {
          return (
            <Grid
              item
              xs={8}
              key={`vehicle-title-${vehicle.id}`}
              container
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <VehicleName vehicleId={vehicle?.id} />
              <Typography sx={{ fontWeight: 600 }}>
                {centsToLocalString(totalPrice, true)}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

function VehicleName({ vehicleId }) {
  const titleByProductName = "";

  const registrationPlate = "";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="caption">
        {titleByProductName ?? <span>[POISTETTU TUOTE]</span>}
      </Typography>
      <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.4)" }}>
        {registrationPlate}
      </Typography>
    </Box>
  );
}
