import { Stack, TextField } from "@mui/material";

import { useState } from "react";

export const ContractAdditionalAgreement = ({
  contractSettings,
  setContractSettings,
}: {
  contractSettings: Record<string, string | boolean>;
  selectedLanguage: string;
  setContractSettings: (value: Record<string, string | boolean>) => void;
}) => {
  const [inputValue, setInputValue] = useState(
    () => contractSettings?.contractAdditionalAgreement || ""
  );
  if (contractSettings?.showContractAdditionalAgreement)
    return (
      <Stack>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() =>
            setContractSettings({
              ...contractSettings,
              contractAdditionalAgreement: inputValue,
            })
          }
          size="small"
          variant="standard"
          inputProps={{
            style: {
              fontWeight: "bolder",
              fontSize: "12px",
            },
          }}
          fullWidth
          multiline
        />
      </Stack>
    );
};
