import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { typeOfProducts } from "~/shared/ProductClassification/type-of-products";
import { useField, useFormikContext } from "formik";
import { centsToLocalString } from "../../../../../shared/money";
import { useGetExternalUserOffers } from "../../../../../hooks/useGetExternalUserOffers";

export default function SelectedOfferCard(props) {
  const { field } = props;
  const [, meta] = useField(field);
  const offer = meta.value[0];

  const producTypeImage = typeOfProducts.find(
    (type) => type.id === offer?.category?.typeId
  )?.image;

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("md"));
  if (offer)
    if (mobileView) {
      return (
        <List disablePadding>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar src={producTypeImage} variant="rounded" />
            </ListItemAvatar>
            <ListItemText
              primary={offer?.name}
              secondary={offer?.exampleModel}
            />
            <ListItemSecondaryAction>
              <PriceField categoryId={offer?.categoryId} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      );
    } else {
      return (
        <Card
          sx={{
            width: "100%",
            paddingTop: "10px",
            border: "none",
            boxShadow: "none",
          }}
        >
          <CardMedia sx={{ height: "170px" }} image={producTypeImage} />

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {offer?.hanselClass
                ? `Hansel-luokka: ${offer?.hanselClass}`
                : offer?.name}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {offer?.exampleModel}
            </Typography>
            <PriceField categoryId={offer?.categoryId} />
          </CardContent>
        </Card>
      );
    }
}

// TODO: Add a pricefield that gets value from reservation data

function PriceField(props) {
  const { categoryId } = props;
  const { values } = useFormikContext();
  const { offers, loading } = useGetExternalUserOffers(values);
  const offer = offers.find((offer) => offer.id === categoryId);

  if (loading)
    return (
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"flex-end"}
        height={"35px"}
      >
        <CircularProgress size={31} />
      </Box>
    );

  return (
    <Box
      display={"flex"}
      width={"100%"}
      justifyContent={"flex-end"}
      height={"35px"}
    >
      <Typography align="right" sx={{ fontSize: "1.3rem" }}>
        <b>{centsToLocalString(offer?.price, true)}</b>
      </Typography>
    </Box>
  );
}
