import { getGlobalLocalizationConfig } from "./localizationConfig";

export const getCurrencyUnit = () => {
  const { locale, currency } = getGlobalLocalizationConfig();

  // Use Intl.NumberFormat to get the currency symbol
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0, // Simplify for symbol extraction
    maximumFractionDigits: 0,
  });

  // Extract only the currency symbol
  const parts = formatter.formatToParts(1); // Format a dummy value to extract symbol
  const currencySymbol = parts.find((part) => part.type === "currency");

  return currencySymbol ? currencySymbol.value : currency; // Fallback to ISO code
};

export const centsToLocalString = (cents, includeUnit) => {
  const { locale, currency } = getGlobalLocalizationConfig();

  const whole = cents / 100;
  if (isNaN(whole)) return "";

  return whole.toLocaleString(locale, {
    style: includeUnit ? "currency" : "decimal",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

// Parses a localized string input into cents
export const parseToCents = (str, currency) => {
  const { currency: defaultCurrency } = getGlobalLocalizationConfig();
  const effectiveCurrency = currency || defaultCurrency;

  function parseWhole(wholeStr) {
    const value = parseInt(wholeStr, 10);
    if (Number.isNaN(value)) {
      throw new Error("Parsing failed for: " + wholeStr);
    }
    return value;
  }

  function parseCents(centsStr) {
    if (centsStr == null || centsStr === "") {
      return 0;
    }
    if (centsStr.length === 1) {
      centsStr = centsStr + "0";
    }
    const value = parseInt(centsStr, 10);
    if (Number.isNaN(value)) {
      throw new Error("Parsing failed for: " + centsStr);
    }
    return value;
  }

  if (typeof str === "string") {
    str = str.replace(/\s+/g, ""); // Remove spaces
    str = str.replace(/[‐−–]/g, "-"); // Normalize negative signs
  }

  const fractionalCurrencies = ["EUR", "SEK", "NOK"];
  const hasFractionalPart = fractionalCurrencies.includes(effectiveCurrency);

  const re = new RegExp(`^(-?)(?<whole>[0-9]+)([,.](?<cents>[0-9]?[0-9]?))?$`);
  const match = re.exec(str);
  if (match) {
    const isNegative = match[1] === "-" ? -1 : 1;
    const whole = parseWhole(match.groups.whole);
    const cents = hasFractionalPart ? parseCents(match.groups.cents) : 0;

    return (whole * 100 + cents) * isNegative;
  } else {
    return null;
  }
};
