/*
 * Allowed currency values for locales: EUR (fi-FI), SEK (sv-SE), NOK (nb-NO)
 */

let globalLocalizationConfig = {
  locale: "fi-FI",
  currency: "EUR",
};

export const setGlobalLocalizationConfig = (config) => {
  console.log("Setting localization config", { config });
  globalLocalizationConfig = { ...globalLocalizationConfig, ...config };
};

export const getGlobalLocalizationConfig = () => globalLocalizationConfig;
