import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { translatedFields } from "../formik/fields";
import { useFormikContext } from "formik";
import {
  centsToLocalString,
  getCurrencyUnit,
  parseToCents,
} from "../../../shared/money";
import { getTotalPrice } from "../utils/utilities";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useAtomValue, useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../atoms/reservationDataAtom";
import { useGetOfferPrices } from "../../../hooks/useGetOfferPrices";
import { reservationTotalPriceAtom } from "../../../stores/reservationTotalPrice";
// validateFields = fields that are checked for changes
// checkProductPrices = enable checking product prices for changes
// checkServicePrices = enable checking service prices for changes
export const useValidateChanges = (
  values,
  validateFields,
  checkProductPrices,
  checkServicePrices,
  handleClose
) => {
  const reservationData = useAtomValue(reservationDataAtom);
  const totalPrice = useAtomValue(reservationTotalPriceAtom);
  const [open, setOpen] = useState(false);
  const [confirmFields, setConfirmFields] = useState({});
  const { offerPrices } = useGetOfferPrices(values);
  const validateChanges = async () => {
    if (!reservationData?.id) return true;

    // Check if any of the fields have changed
    const changedFields = validateFields.reduce((acc, field) => {
      const oldValue = reservationData[field]
        ? reservationData[field]
        : "Tyhjä kenttä";
      const newValue = values[field] ? values[field] : "Tyhjä kenttä";
      if (oldValue !== newValue) {
        acc[field] = `${oldValue} => ${newValue}`;
      }
      return acc;
    }, {});

    // Check if any of the product prices have changed
    if (checkProductPrices) {
      values.reservationVehicles.forEach((vehicle, index) => {
        const fromReservationData = reservationData.reservationVehicles.find(
          (v) => v.id === vehicle.id
        );

        if (
          fromReservationData &&
          fromReservationData.price !== parseToCents(vehicle.price)
        ) {
          return (changedFields[`product-${index}`] = `${
            vehicle?.name
          } hinta: ${vehicle.price}${getCurrencyUnit()} => ${centsToLocalString(
            fromReservationData.price,
            true
          )}`);
        }
      });
    }

    if (checkServicePrices) {
      values.additionalServices.forEach((service, index) => {
        const fromReservationData = reservationData.additionalServices.find(
          (s) => s.id === service.id
        );

        if (
          fromReservationData &&
          fromReservationData.price !== parseToCents(service.price)
        ) {
          return (changedFields[`service-${index}`] = `${
            service?.description
          } hinta: ${service.price}${getCurrencyUnit()} => ${centsToLocalString(
            fromReservationData.price,
            true
          )}`);
        }
      });
    }

    // Check if the total price has changed
    if (
      reservationData?.paymentData?.paymentTotal &&
      reservationData.paymentData.paymentTotal !== totalPrice
    ) {
      changedFields["totalPrice"] = ` ${centsToLocalString(
        reservationData.paymentData.paymentTotal,
        true
      )} => ${totalPrice}${getCurrencyUnit()}`;
    }
    if (Object.values(changedFields).length > 0) {
      setConfirmFields(changedFields);
      setOpen(true);
    } else {
      return true;
    }
  };
  const validateChangesDialog = (
    <ValidateChangesDialog
      open={open}
      setOpen={setOpen}
      confirmFields={confirmFields}
      handleClose={handleClose}
    />
  );

  return { validateChanges, validateChangesDialog };
};

function ValidateChangesDialog(props) {
  const { open, setOpen, confirmFields, handleClose } = props;
  const { submitForm, isSubmitting } = useFormikContext();
  const setEditReservationData = useSetAtom(reservationDataAtom);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle>
        <Stack flex={1} direction={"row"} spacing={1} alignItems={"center"}>
          <WarningAmberIcon color="warning" sx={{ fontSize: "2rem" }} />
          <Typography variant="h6">
            Seuraavat kentät ovat muuttuneet:
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <List sx={{ marginLeft: "2rem" }}>
          {Object.entries(confirmFields).map(([key, value]) => (
            <ListItem key={key}>
              <FieldKey keyValue={key} value={value} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={() => {
            if (handleClose) {
              handleClose();
            } else {
              setOpen(false);
            }
          }}
        >
          Sulje
        </Button>
        <Button
          disabled={isSubmitting}
          onClick={async () => {
            try {
              const result = await submitForm();
              if (result?.success) {
                setEditReservationData(null);
              } else {
                // TODO: handle error
              }
            } catch (error) {
              console.error(error);
            }
          }}
          variant="contained"
          color="success"
        >
          Tallenna muutokset
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FieldKey(props) {
  const selectedLanguage = "FIN";

  const { keyValue, value } = props;
  if (keyValue.startsWith("product")) {
    return <Typography>{value}</Typography>;
  } else {
    return (
      <Typography>
        {translatedFields[selectedLanguage][keyValue]}: {value}
      </Typography>
    );
  }
}
