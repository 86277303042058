import { Box, Button, Grid, Typography } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import InsuranceInvoiceItem from "./components/InsuranceInvoiceItem";
import InvoiceItem from "./components/InvoiceItem";
import { getDefaultInvoiceItem } from "../../form/defaultValues";
import { formatDate } from "~/utils/date";
import { Fragment } from "react";

function InvoiceItemsInput() {
  const { values } = useFormikContext<any>();

  return (
    <Grid container item spacing={1}>
      <FieldArray
        name="items"
        render={(arrayHelpers) => (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            {values.items.map((item: any, index: number) => {
              let ItemComponent = InvoiceItem;

              if (item.insuranceNonEditable) {
                ItemComponent = InsuranceInvoiceItem;
              }

              return (
                <Fragment key={index}>
                  {values.items[index - 1]?.reservation?.id !==
                    item?.reservation?.id && (
                    <Box sx={{ display: "flex", gap: 3, marginBottom: -2 }}>
                      <Typography
                        style={{
                          fontFamily: "Sofia Pro",
                          fontSize: 20,
                        }}
                      >
                        Laskurivit
                      </Typography>
                      {item.reservation?.startTime &&
                        item.reservation?.endTime && (
                          <Typography
                            style={{
                              fontFamily: "Sofia Pro",
                              fontSize: 20,
                              color: "#545454",
                            }}
                          >
                            {formatDate(item.reservation.startTime as string) +
                              " - " +
                              formatDate(item.reservation.endTime as string)}
                          </Typography>
                        )}
                    </Box>
                  )}

                  <ItemComponent
                    key={index}
                    index={index}
                    item={item}
                    arrayHelpers={arrayHelpers}
                  />
                </Fragment>
              );
            })}

            <Grid item>
              <Button
                variant="outlined"
                onClick={() => arrayHelpers.push(getDefaultInvoiceItem())}
              >
                Lisää rivi
              </Button>
            </Grid>
          </Box>
        )}
      />
    </Grid>
  );
}

export default InvoiceItemsInput;
