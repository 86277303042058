import { Grid, InputAdornment, MenuItem } from "@mui/material";
import FormikInputSelectArray from "~/components/Inputs/FormikInputSelectArray";
import FormikInputTextArray from "~/components/Inputs/FormikInputTextArray";
import { getVatConfig } from "~/configs/vat-values";
import { Invoice } from "~/graphql/API";
import { getTotal } from "~/utils/getTotal";
// @ts-ignore
import { getCurrencyUnit } from "~/shared/money";

type InvoiceItemProps = {
  item: Invoice["items"][0];
};

function InvoiceItem({ item }: InvoiceItemProps) {
  const { VAT_VALUES } = getVatConfig();
  const unitPrice = item.unitprice / 100;
  const dailyUnit = item.unit === "pv";

  return (
    <Grid container item spacing={1}>
      <FormikInputTextArray
        readOnly
        label="Tuotenimi"
        xs={6}
        value={item.name}
      />
      <FormikInputTextArray
        readOnly
        label="Tuoteselite"
        xs={6}
        value={item.description}
      />
      <FormikInputTextArray
        label={(dailyUnit ? "Kokonaishinta" : "Hinta") + " (sis. alv)"}
        xs={6}
        md={2}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{getCurrencyUnit()}</InputAdornment>
          ),
        }}
        readOnly
        value={unitPrice}
      />
      <FormikInputSelectArray
        readOnly
        disabled
        label="ALV %"
        type="number"
        xs={6}
        md={2}
        value={item.vat}
      >
        {Object.entries(VAT_VALUES).map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </FormikInputSelectArray>
      <FormikInputTextArray
        label={(dailyUnit ? "Kokonaishinta" : "Hinta") + " (ilman alv)"}
        xs={6}
        md={2}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{getCurrencyUnit()}</InputAdornment>
          ),
        }}
        readOnly
        value={getTotal(item.unitprice, {
          tax: parseInt(`${item.vat}`),
          removeTax: true,
        }).toFixed(2)}
      />
      {/* <Field
        readOnly
        index={index}
        label="Osuus"


        xs={6}
        md={2}
        disabled
        value={item.pricePortion}
        InputProps={{
          endAdornment: <InputAdornment position="end">{getCurrencyUnit()}</InputAdornment>,
        }}
        hidden={true} // Not is use, at least for now
      /> */}

      {dailyUnit ? (
        <FormikInputTextArray
          readOnly
          label="Päiviä"
          type="number"
          xs={6}
          md={2}
          value={item.quantity}
        />
      ) : (
        <FormikInputTextArray
          readOnly
          label="Määrä"
          type="number"
          xs={6}
          md={2}
          value={item.quantity}
        />
      )}

      <FormikInputSelectArray
        readOnly
        label="Yksikkö"
        xs={6}
        md={1}
        disabled
        value={item.unit}
      >
        <MenuItem value="kpl">kpl</MenuItem>
        <MenuItem value="pv">pv</MenuItem>
      </FormikInputSelectArray>

      {dailyUnit && (
        <FormikInputTextArray
          readOnly
          label="Päivähinta"
          xs={6}
          md={2}
          disabled
          value={unitPrice / item.quantity}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getCurrencyUnit()}
              </InputAdornment>
            ),
          }}
        />
      )}
    </Grid>
  );
}

export default InvoiceItem;
