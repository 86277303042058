import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { centsToLocalString } from "../../../../shared/money";

const InvoiceItemsTable = ({ invoiceItems, invoiceItemsSum, onRowClick }) => {
  // Function to determine row click behavior and cursor style
  const getRowProps = (item) => {
    if (onRowClick) {
      return {
        onClick: () =>
          onRowClick(
            (parseFloat(item.quantity) * parseFloat(item.priceIncVAT)).toFixed(
              2
            )
          ),
        style: { cursor: "pointer" },
      };
    }
    return {};
  };
  return (
    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Typography variant="body1" sx={{ ml: 2, my: 1, fontWeight: "bold" }}>
        Maksurivit
      </Typography>
      <Table aria-label="Maksurivit" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Tuote</TableCell>
            <TableCell align="right">Määrä</TableCell>
            <TableCell align="right">Hinta ilman ALV</TableCell>
            <TableCell align="right">ALV</TableCell>
            <TableCell align="right">Hinta sis. ALV</TableCell>
            <TableCell align="right">Yhteensä</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceItems.length ? (
            invoiceItems.map((item) => (
              <TableRow key={item.id} {...getRowProps(item)}>
                <TableCell>{item.description}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">
                  {centsToLocalString(item.priceExVAT, true)}
                </TableCell>
                <TableCell align="right">{item.VAT}</TableCell>
                <TableCell align="right">
                  {centsToLocalString(item.priceIncVAT, true)}
                </TableCell>
                <TableCell align="right">
                  {centsToLocalString(
                    parseFloat(item.quantity) * parseFloat(item.priceIncVAT),
                    true
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                Varauksella ei ole tuotteita.
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={5} align="right" style={{ fontWeight: "bold" }}>
              Summa:
            </TableCell>
            <TableCell align="right" style={{ fontWeight: "bold" }}>
              {centsToLocalString(invoiceItemsSum, true)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceItemsTable;
