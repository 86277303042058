import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import {
  centsToLocalString,
  getCurrencyUnit,
  parseToCents,
} from "../../../../../../../../../../shared/money";
import { Euro } from "@mui/icons-material";
import ListingPrice from "./ListingPrice";
import PriceLoading from "../../../../../../../../components/PriceLoading";
import { useAtomValue, useSetAtom } from "jotai";
import { vehiclePricesAtom } from "../../../../../../../../../../stores/vehiclePrices";
import { reservationDataAtom } from "../../../../../../../../../../atoms/reservationDataAtom";

export default function PriceField(props) {
  const {
    vehicleId,
    priceLoading,
    listingPrice,
    expanded,
    fieldInput,
    setFieldInput,
  } = props;
  const reservationData = useAtomValue(reservationDataAtom);
  const prices = useAtomValue(vehiclePricesAtom);
  const price = prices[vehicleId]?.price;
  const setPrice = useSetAtom(vehiclePricesAtom);
  const { values } = useFormikContext();
  const handleChangePrice = () => {
    if (fieldInput === "" || fieldInput === null) {
      return setPrice((prev) => ({
        ...prev,
        [vehicleId]: {
          price: listingPrice,
          startTime: values.startTime,
          returnTime: values.returnTime,
          pricingCategoryId: values.pricingCategoryId,
          deductible: prices[vehicleId]?.deductible,
          tax: prices[vehicleId]?.tax,
        },
      }));
    }
    setFieldInput(centsToLocalString(parseToCents(fieldInput)));
    setPrice((prev) => ({
      ...prev,
      [vehicleId]: {
        price: parseToCents(fieldInput),
        startTime: values.startTime,
        returnTime: values.returnTime,
        pricingCategoryId: values.pricingCategoryId,
        deductible: prices[vehicleId]?.deductible,
        tax: prices[vehicleId]?.tax,
      },
    }));
  };

  if (!expanded) {
    if (priceLoading) {
      return <PriceLoading />;
    }
    return <Price price={price} />;
  }

  const handleResetPrice = () => {
    const price = reservationData?.reservationVehicles?.find(
      (vehicle) => vehicle.id === vehicleId
    )?.price;
    setFieldInput(centsToLocalString(price));
    setPrice((prev) => ({
      ...prev,
      [vehicleId]: {
        price: price,
        startTime: values.startTime,
        returnTime: values.returnTime,
        pricingCategoryId: values.pricingCategoryId,
      },
    }));

    console.log("reset trigger");
  };

  const hasPreviousPrice =
    reservationData?.reservationVehicles?.length > 0
      ? reservationData?.reservationVehicles.find(
          (vehicle) => vehicle.id === vehicleId
        )?.price
      : false;

  return (
    <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
      {hasPreviousPrice ? (
        <Tooltip title="Voit palauttaa varaukselle aikaisemmin tallennetun hinnan">
          <Button sx={{ textTransform: "none" }} onClick={handleResetPrice}>
            Palauta
          </Button>
        </Tooltip>
      ) : null}
      <Stack>
        <ListingPrice
          listingPrice={listingPrice}
          loading={priceLoading}
          vehicleId={vehicleId}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          inputProps={{ style: { textAlign: "right", marginRight: "5px" } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getCurrencyUnit()}
              </InputAdornment>
            ),
          }}
          label="Hinta"
          onBlur={handleChangePrice}
          onChange={(e) => setFieldInput(e.target.value)}
          placeholder={centsToLocalString(listingPrice)}
          size="small"
          sx={{ maxWidth: "130px" }}
          value={fieldInput ?? ""}
        />
      </Stack>
    </Stack>
  );
}

function Price(props) {
  const { price } = props;
  return (
    <Typography sx={{ fontFamily: "Sofia Pro", fontSize: "1.3rem" }}>
      {centsToLocalString(price, true)}
    </Typography>
  );
}
