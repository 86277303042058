import "./EconomyDialog.css";
import { Grid, Typography } from "@mui/material";

import { centsToLocalString } from "../../shared/money";
import { FcPuzzle } from "react-icons/fc";
export default function MobileViewAdditionalServices({
  reservationServices,
  theme,
}) {
  return (
    <>
      {reservationServices.map((a) => {
        return (
          <Grid
            key={a.key}
            container
            direction="row"
            alignItems="center"
            style={{
              marginTop: 10,
              borderStyle: "solid",
              borderColor: "lightgrey",
              backgroundColor: "#ffffff",
              borderWidth: 1,
              boxShadow: theme.shadows[3],
              padding: 7,
              borderRadius: 5,
              position: "relative",
            }}
          >
            <FcPuzzle
              style={{
                fontSize: 70,
                position: "absolute",
                bottom: 0,
                right: 0,
                opacity: 0.6,
                zIndex: 0,
              }}
            />

            <Grid item xs={10} style={{ marginLeft: 5 }}>
              <Grid item xs={12}>
                <Typography
                  component="span"
                  style={{
                    color: "black",
                    fontWeight: 600,
                    fontFamily: "Sofia Pro",
                    letterSpacing: "0.05em",
                    fontSize: 16,
                  }}
                >
                  {a.description}
                </Typography>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: "Sofia Pro",
                      fontSize: 16,
                    }}
                  >
                    <>{centsToLocalString(a.total, true)}</>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "grey",
                    fontFamily: "Roboto",
                    fontSize: 13,
                  }}
                  align="left"
                >
                  <>
                    Palvelua on vuokrattu {a.quantity}{" "}
                    {a.quantity === 1 ? "kerran." : "kertaa."}
                  </>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
