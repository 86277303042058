import { Delete, Extension } from "@mui/icons-material";

import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  useTheme,
} from "@mui/material";
import { useField } from "formik";
import { centsToLocalString } from "../../../../../shared/money";
import { ServiceQuantitySelector } from "../../../utils/utilities";
import { useCategoryImage } from "~/hooks/useCategoryImage";

export default function SelectedAdditionalServiceCard(props) {
  const { service } = props;
  const [field, , helpers] = useField("additionalServices");
  const { setValue } = helpers;
  const handleDelete = () => {
    setValue(field.value.filter((s) => s.key !== service.key));
  };
  const { image } = useCategoryImage(service);
  const theme = useTheme();
  return (
    <ListItem disableGutters>
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          src={image}
          sx={{ bgcolor: !image && theme.palette.primary.main }}
        >
          <Extension />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={service?.description ?? "Lisäpalvelu"}
        secondary={`Hinta: ${centsToLocalString(service?.offerPrice, true)}`}
      />

      <ListItemSecondaryAction sx={{ bgcolor: "white" }}>
        {service?.maxQuantity > 1 ? (
          <ServiceQuantitySelector service={service} />
        ) : (
          <IconButton onClick={handleDelete}>
            <Delete />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
