import { Button, Grid, InputAdornment, MenuItem } from "@mui/material";
import { Field, FieldArrayRenderProps, useFormikContext } from "formik";
import FormikInputSelectArray from "~/components/Inputs/FormikInputSelectArray";
import FormikInputTextArray from "~/components/Inputs/FormikInputTextArray";
import { getVatConfig } from "~/configs/vat-values";
//@ts-ignore
import { getCurrencyUnit } from "~/shared/money";
import { getTotal } from "~/utils/getTotal";

type InvoiceItemProps = {
  index: number;
  item: any;
  arrayHelpers: FieldArrayRenderProps;
};

function InvoiceItem({ index, item, arrayHelpers }: InvoiceItemProps) {
  const { VAT_VALUES } = getVatConfig();
  const { values } = useFormikContext<any>();

  const dailyUnit = item.unit === "pv";
  const isDisabled = item.editable === false;

  function inputProps(field: string) {
    return {
      index: index,
      arrayName: "items",
      attributeName: field,
      name: `items.${index}.${field}`,
    };
  }

  return (
    <Grid container item spacing={1}>
      <Field
        {...inputProps("name")}
        label="Tuotenimi"
        component={FormikInputTextArray}
        required
        xs={6}
      />
      <Field
        {...inputProps("description")}
        label="Tuoteselite"
        component={FormikInputTextArray}
        xs={6}
      />
      <Field
        {...inputProps("unitprice")}
        label={(dailyUnit ? "Kokonaishinta" : "Hinta") + " (sis. alv)"}
        component={FormikInputTextArray}
        required
        xs={6}
        md={2}
        disabled={isDisabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{getCurrencyUnit()}</InputAdornment>
          ),
        }}
      />
      <Field
        {...inputProps("vat")}
        label="ALV %"
        component={FormikInputSelectArray}
        type="number"
        xs={6}
        md={2}
        disabled={isDisabled}
      >
        {Object.entries(VAT_VALUES).map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Field>
      <FormikInputTextArray
        label={(dailyUnit ? "Kokonaishinta" : "Hinta") + " (ilman alv)"}
        xs={6}
        md={2}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{getCurrencyUnit()}</InputAdornment>
          ),
        }}
        readOnly
        value={getTotal(values.items[index].unitprice * 100, {
          tax: parseInt(values.items[index].vat),
          removeTax: true,
        }).toFixed(2)}
      />
      {/* <Field

        index={index}
        label="Osuus"
        component={FormikInputTextArray}
        required
        xs={6}
        md={2}
        disabled
        value={item.pricePortion}
        InputProps={{
          endAdornment: <InputAdornment position="end">{getCurrencyUnit()}</InputAdornment>,
        }}
        hidden={true} // Not is use, at least for now
      /> */}

      {dailyUnit ? (
        <Field
          label="Päiviä"
          component={FormikInputTextArray}
          type="number"
          // value={rentalDays}
          required
          xs={6}
          md={2}
          disabled={true}
        />
      ) : (
        <Field
          {...inputProps("quantity")}
          label="Määrä"
          component={FormikInputTextArray}
          type="number"
          required
          xs={6}
          md={2}
          disabled={isDisabled}
        />
      )}

      <Field
        {...inputProps("unit")}
        label="Yksikkö"
        component={FormikInputSelectArray}
        xs={6}
        md={1}
        disabled={isDisabled}
      >
        <MenuItem value="kpl">kpl</MenuItem>
        <MenuItem value="pv">pv</MenuItem>
      </Field>

      {dailyUnit && (
        <Field
          label="Päivähinta"
          component={FormikInputTextArray}
          required
          xs={6}
          md={2}
          disabled
          value={item.priceperday}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      )}

      <Grid item>
        <Button
          variant="contained"
          onClick={() => arrayHelpers.remove(index)}
          disabled={item.editable === false || item.insuranceNonRemovable}
        >
          -
        </Button>
      </Grid>
    </Grid>
  );
}

export default InvoiceItem;
