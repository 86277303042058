import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { EServiceCategories } from "../../../utils/enums";
import { useAddNewService } from "../../../hooks/useAddNewService";
import { getVatConfig } from "~/configs/vat-values";

const { DEFAULT_VAT_VALUE } = getVatConfig();

export default function AddCustomService() {
  const [newAdditionalServiceName, setNewAdditionalServiceName] = useState("");
  const category = EServiceCategories.EXPENDABLE;
  const addNewServiceOffer = [];
  const createNewAdditionalService = () => {
    if (newAdditionalServiceName === "") {
      return;
    }
    const id = uuidv4();

    const newService = {
      key: `custom-service-${id}`,
      description: newAdditionalServiceName,
      offerPrice: 0,
      category: category,
      quantity: 1,
      tax: DEFAULT_VAT_VALUE,
    };
    addService(newService, null);
    setNewAdditionalServiceName("");
  };
  const { addService } = useAddNewService();

  return (
    <Stack direction={"row"} spacing={1}>
      <TextField
        label="Lisää kertakäyttöpalvelu"
        value={newAdditionalServiceName}
        onChange={(e) => setNewAdditionalServiceName(e.target.value)}
      />
      <Button onClick={createNewAdditionalService}>Tallenna</Button>
    </Stack>
  );
}
