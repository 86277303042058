import {
  Grid,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";

import { centsToLocalString, getCurrencyUnit } from "../shared/money";
import useGetVehicleObjById from "./CustomHooks/useGetVehicleObjById";

const LoadablePrice = ({ price, isLoading, quantity }) => {
  if (isLoading) {
    return <CircularProgress style={{ height: "1em", width: "1em" }} />;
  } else if (price == null) {
    return "-";
  } else if (quantity > 1) {
    return (
      centsToLocalString(price * quantity) +
      " (" +
      quantity +
      "x" +
      centsToLocalString(price, true) +
      ")"
    );
  } else {
    return centsToLocalString(price, true);
  }
};

export default function PriceField({
  formik,
  name,
  defaultPrice,
  isDefaultPriceLoading,
  enableQuantity,
  vehicleQuantity,
  productId,
  ...props
}) {
  const productData = useGetVehicleObjById(productId);
  // Price is multiplied if quantity is set (enableQuantity) or vehicleQuantity is given
  const getQuantity = () => {
    if (enableQuantity) {
      if (formik.values[name + "Quantity"] > 1) {
        return formik.values[name + "Quantity"];
      }
      return 1;
    }
    if (vehicleQuantity > 1) {
      return vehicleQuantity;
    }
    return 1;
  };
  const defaultPriceStr =
    defaultPrice != null
      ? centsToLocalString(defaultPrice * getQuantity())
      : "";
  return (
    <>
      <Grid item sx={{ fontSize: "14px" }}>
        Listahinta:{" "}
        <LoadablePrice
          price={defaultPrice}
          isLoading={isDefaultPriceLoading}
          quantity={getQuantity()}
        />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={enableQuantity ? 9 : 12}>
          <TextField
            {...props}
            label={productData?.name}
            placeholder={defaultPriceStr}
            value={formik.values[name] || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name={name}
            variant="outlined"
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={formik.touched[name] && formik.errors[name]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getCurrencyUnit()}
                </InputAdornment>
              ),
            }}
            fullWidth
            size="small"
          />
        </Grid>
        {enableQuantity && (
          <Grid item xs={3}>
            <TextField
              label={"Määrä"}
              value={formik.values[name + "Quantity"] || 1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={name + "Quantity"}
              variant="outlined"
              error={formik.touched[name] && !!formik.errors[name]}
              helperText={formik.touched[name] && formik.errors[name]}
              size="small"
              type="number"
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
