import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { centsToLocalString } from "../../../../shared/money";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailIcon from "@mui/icons-material/Mail";
import PendingIcon from "@mui/icons-material/Pending";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { format } from "date-fns";
import { OpenInNew } from "@mui/icons-material";

export const livionDataField = {
  field: "livionData",
  headerName: "Livion data",
  sortable: false,
  width: 150,
};

export const authDataField = {
  field: "authStatus",
  headerName: "Tunnistautuminen",
  sortable: "false",
  hidden: true,
  minWidth: 100,
  flex: 1,
  renderCell: (params) => {
    if (params?.value === "COMPLETED") {
      return (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon sx={{ color: "#4caf50" }} />
        </Box>
      );
    }

    if (params?.value === "PENDING_MUST") {
      return (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <PendingIcon sx={{ color: "#F18F01" }} />
        </Box>
      );
    }

    if (params?.value === "PENDING") {
      return (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <PendingIcon sx={{ color: "#F18F01" }} />
        </Box>
      );
    }

    return "";
  },
};

const companyField = {
  field: "company",
  headerName: "Toimipiste",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const channel = {
  field: "channel",
  headerName: "Varauskanava",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const type = {
  field: "type",
  headerName: "Tyyppi",
  sortable: true,
  flex: 1,
  minWidth: 200,
  valueFormatter: (params) => {
    if (params?.value === "INSURANCE") {
      return "Vakuutus";
    }
    if (params?.value === "HANSEL") {
      return "Hansel";
    }

    return params?.value;
  },
};

const startTimeField = {
  field: "startTime",
  headerName: "Alkamisajankohta",
  sortable: true,
  flex: 0.5,
  minWidth: 150,
  valueFormatter: (params) => {
    if (params?.value == null) {
      return "";
    }
    return `${format(new Date(params?.value), " dd.MM.yyyy HH:MM")}`;
  },
};

const endTimeField = {
  field: "endTime",
  headerName: "Päättymisajankohta",
  sortable: true,
  flex: 0.5,
  minWidth: 150,
  valueFormatter: (params) => {
    if (params?.value == null) {
      return "";
    }
    return `${format(new Date(params?.value), " dd.MM.yyyy HH:MM")}`;
  },
};

const shortId = {
  field: "shortId",
  align: "center",
  headerName: "Varausnumero",
  sortable: true,
  flex: 0.5,
  minWidth: 100,
};

const vehiclesField = {
  field: "vehicle",
  headerName: "Rekisterinumero",
  sortable: true,
  flex: 0.5,
  minWidth: 100,
};
const vehicleCategoryNameField = {
  field: "vehicleCategoryName",
  headerName: "Kategoria",
  sortable: true,
  flex: 1,
  minWidth: 200,
};
const vehicleNameField = {
  field: "vehicleName",
  headerName: "Tuote",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const openReservation = {
  field: "varaus",
  headerName: "",
  sortable: true,
  flex: 0.2,
  minWidth: 70,
  renderCell: (params) => {
    if (!params.row.varaus) return;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => params.row.openReservation(params.row.varaus)}
        >
          <OpenInNew color="success" />
        </IconButton>
      </Box>
    );
  },
};

const priceField = {
  field: "price",
  headerName: "Hinta",
  type: "number",
  sortable: true,
  flex: 0.5,
  minWidth: 150,
  valueGetter: (params) => {
    const calcPrices = params?.value?.reduce((acc, curr) => {
      return acc + curr.price;
    }, 0);
    return calcPrices;
  },
  valueFormatter: (params) => {
    if (params?.value == null) {
      return "";
    }
    return `${centsToLocalString(params?.value, true)}`;
  },
};
const totalPriceField = {
  field: "totalPrice",
  headerName: "Kokonaissumma",
  type: "number",
  sortable: true,
  flex: 0.5,
  minWidth: 150,
  valueFormatter: (params) => {
    if (params?.value == null) {
      return "";
    }
    return `${centsToLocalString(params?.value, true)}`;
  },
  renderCell: (params) => {
    if (params?.value)
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 550 }}>
            {centsToLocalString(params?.value, true)}
          </Typography>
        </Box>
      );
  },
};

const paymentStatusField = {
  field: "paymentStatus",
  headerName: "Maksun tila",
  sortable: true,
  flex: 0.5,
  minWidth: 100,
  editable: true,
  renderCell: (params) => {
    if (params.row.billPayment) {
      return (
        <Tooltip title="Laskutus">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {params?.value === "COMPLETED" ? (
              <MailIcon sx={{ color: "#4caf50" }} />
            ) : (
              <MailIcon sx={{ color: "#4caf50" }} />
            )}
          </Box>
        </Tooltip>
      );
    }
    if (params?.value === "COMPLETED" || params?.value === "COMPLETED_MANUAL") {
      return (
        <Tooltip title="Maksu on suoritettu">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CheckCircleIcon sx={{ color: "#4caf50" }} />
          </Box>
        </Tooltip>
      );
    }

    if (params?.value === "COMPLETED_INVOICE") {
      return (
        <Tooltip title="Laskutettu">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <ReceiptLongIcon sx={{ color: "#4caf50" }} />
          </Box>
        </Tooltip>
      );
    }

    if (params?.value === "PENDING" || params?.value === "PENDING_INVOICE") {
      return (
        <Tooltip title="Maksu on vireillä">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <PendingIcon sx={{ color: "#F18F01" }} />
          </Box>
        </Tooltip>
      );
    }
    if (params?.value === "CANCELLED") {
      return "";
    }
    if (
      params?.value === "COMPLETED_DEPOSIT" ||
      params?.value === "PENDING_FULL"
    ) {
      return (
        <Tooltip title="Varausmaksu suoritettu">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <ReceiptLongIcon sx={{ color: "#8D8D92" }} />
          </Box>
        </Tooltip>
      );
    }
  },
};

const customerNameField = {
  field: "name",
  headerName: "Asiakkaan nimi",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

export const defaultColumns = [
  openReservation,
  companyField,
  shortId,
  startTimeField,
  endTimeField,
  vehiclesField,
  vehicleNameField,
  vehicleCategoryNameField,
  priceField,
  totalPriceField,
  paymentStatusField,
  customerNameField,
  channel,
  type,
];

export const columnsExternalBusinessAdmin = [
  startTimeField,
  endTimeField,
  customerNameField,
];
