import React from "react";
import "./EconomyDialog.css";
import { Grid, Typography, Paper } from "@mui/material";

import { centsToLocalString } from "../../shared/money";

export default function MobileViewProducts({
  selectedCategory,
  reservedVehicles,
}) {
  return (
    <>
      {selectedCategory === "showAllCategories"
        ? reservedVehicles.map((vehicle) => <ProductCard vehicle={vehicle} />)
        : reservedVehicles
            .filter((vehicle) => vehicle?.category?.id === selectedCategory)
            .map((vehicle) => <ProductCard vehicle={vehicle} />)}
    </>
  );
}

function ProductCard({ vehicle }) {
  return (
    <Paper
      elevation={2}
      sx={{
        maxHeight: "150px",
        marginTop: "15px",
        position: "relative",
        borderRadius: "10px",
        padding: "10px",
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{
          maxHeight: 150,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -15,
            right: 0,
            backgroundColor: "#37474f",
            color: "#ffffff",
            height: 45,

            borderRadius: 10,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Sofia Pro",
              fontSize: 20,
            }}
          >
            {vehicle?.utilizationRate} %
          </Typography>
        </div>
        <Grid item xs={10} container direction="column">
          <Typography
            component="span"
            style={{
              color: "black",
              fontWeight: 600,
              fontFamily: "Sofia Pro",
              letterSpacing: "0.05em",
              fontSize: 17,
            }}
            noWrap
          >
            {vehicle?.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography
              style={{
                color: "grey",
                fontFamily: "Roboto",
                fontSize: 13,
                fontStyle: "italic",
              }}
              align="left"
            >
              Tuotetta on vuokrattu {vehicle.quantity}{" "}
              {vehicle.quantity === 1 ? "kerran." : "kertaa."}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Grid item>
            {vehicle?.registrationPlate && (
              <Typography
                component="span"
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  color: "#182a58",
                  fontSize: 16,
                  borderRadius: 10,
                  letterSpacing: "0.1em",
                }}
              >
                {vehicle.registrationPlate}
              </Typography>
            )}
          </Grid>

          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            xs={8}
          >
            <Grid item>
              <Typography
                align="right"
                style={{
                  fontFamily: "Sofia Pro",
                  fontSize: 17,
                }}
              >
                {centsToLocalString(vehicle.total, true)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
