import { Grid, Typography, TextField, InputAdornment } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import PricingSelection from "./PricingSelection";
import { useFetchAdditionalServices } from "../services/fetchAdditionalServices";
import { FormGrid } from "./common/FormComponents";
import { useFormikContext } from "formik";
import { getCurrencyUnit } from "../shared/money";

export default function AdditionalServicePricing({
  showPricings,
  filterCategory, // Show only services set on filterCategory
  enabledServices,
  disabled,
}) {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext();
  const { additionalServices } = useFetchAdditionalServices();

  const services = useMemo(() => {
    if (additionalServices) {
      const filteredServices = additionalServices.filter((s) => {
        // Hide services that have hide key value set to true and are not enabled (used for hard coded services)
        if (
          s.hide &&
          !(enabledServices && enabledServices.some((es) => es.key === s.id))
        ) {
          return false;
        }
        // Grouped services are displayed differently
        if (s.isGroupParent || s.groupParentId) return false;
        // Hide removed and default servies that are not applied to Vehicle
        if ((s._removed || s.isDefault) && enabledServices) {
          const found = enabledServices.some((es) => es.key === s.id);
          if (!found) return false;
        }
        // Only show services set on category if using categoryDefaults
        if (filterCategory) {
          return filterCategory?.additionalServices?.items.find(
            (cs) => cs.key === s.id
          );
        }
        return true;
      });
      return filteredServices;
    }
  }, [additionalServices, filterCategory, enabledServices]);
  // If exists, get the grouped deductible services
  const groupedDeductibles = useMemo(() => {
    if (additionalServices) {
      const parent = additionalServices.find(
        (s) => s.category === "deductible" && s.isGroupParent
      );
      if (parent) {
        const children = additionalServices.filter(
          (s) => s.groupParentId === parent.id
        );
        if (parent && children) return { parent: parent, children: children };
      }
      return null;
    }
  }, [additionalServices]);

  return (
    <>
      <FormGrid sx={{ marginY: "5px" }}>
        <Typography
          style={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Lisäpalvelut
        </Typography>
      </FormGrid>
      <Grid container direction="row" spacing={2}>
        {(services || [])
          .filter(
            (service) =>
              service.category === "normal" || service.category === "deductible"
          )
          .map((service) => {
            return (
              <FormGrid md={6} key={service.id}>
                <PricingSelection
                  showPricings={showPricings}
                  name={
                    !!filterCategory ? "category-" + service.id : service.id
                  }
                  label={service.description}
                  disabled={!!filterCategory || disabled}
                />
              </FormGrid>
            );
          })}
      </Grid>
      {groupedDeductibles && (
        <Grid container direction="row" spacing={1} sx={{ marginY: "5px" }}>
          <FormGrid>
            <Typography
              style={{
                fontFamily: "Sofia Pro",
                fontSize: 20,
              }}
            >
              Omavastuu (uusi)
            </Typography>
          </FormGrid>
          {(groupedDeductibles.children || [])
            .sort((s1, s2) => {
              try {
                return s1.groupOrderNumber - s2.groupOrderNumber;
              } catch (e) {
                console.error("Unknown error");
                return -1;
              }
            })
            .map((service) => {
              return (
                <Grid
                  container
                  item
                  spacing={2}
                  key={service.id}
                  direction={"row"}
                >
                  <FormGrid md={6} key={service.id}>
                    <PricingSelection
                      showPricings={showPricings}
                      name={
                        !!filterCategory ? "category-" + service.id : service.id
                      }
                      label={service.description}
                      disabled={!!filterCategory || disabled}
                    />
                  </FormGrid>
                  {values[service.id] && (
                    <FormGrid
                      md={6}
                      key={`${service.id}+deductible-price-field`}
                    >
                      <TextField
                        name={service.id + "Details"}
                        label={"Omavastuu"}
                        value={values[service.id + "Details"] || ""}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        error={
                          touched[service.id + "Details"] &&
                          !!errors[service.id + "Details"]
                        }
                        helperText={
                          touched[service.id + "Details"] &&
                          errors[service.id + "Details"]
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {getCurrencyUnit()}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGrid>
                  )}
                </Grid>
              );
            })}
        </Grid>
      )}
      <Grid container item direction="row" spacing={2} sx={{ marginY: "5px" }}>
        <FormGrid>
          <Typography
            style={{
              fontFamily: "Sofia Pro",
              fontSize: 20,
              marginTop: 15,
            }}
          >
            Kilometrit
          </Typography>
        </FormGrid>
        {(services || [])
          .filter((service) => service.category === "distance")
          .sort((s1, s2) => {
            try {
              const r = /\d+/g;
              const num1 = parseInt(s1.description.match(r)[0]);
              const num2 = parseInt(s2.description.match(r)[0]);
              return num1 - num2;
            } catch (e) {
              // Handle non numerical eg. "Rajattomat kilometrit"
              return -1;
            }
          })
          .map((service) => {
            return (
              <FormGrid key={service.id}>
                <PricingSelection
                  showPricings={showPricings}
                  name={
                    !!filterCategory ? "category-" + service.id : service.id
                  }
                  label={service.description}
                  disabled={!!filterCategory || disabled}
                />
              </FormGrid>
            );
          })}
      </Grid>
    </>
  );
}
