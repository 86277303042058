import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useMemo, useRef, useState } from "react";
import { AppContext } from "../../../AppContext";
import { API, graphqlOperation } from "aws-amplify";
import { createPricingsFromCsv } from "../../../graphql/mutations";
import { centsToLocalString, getCurrencyUnit } from "../../../shared/money";
import { Edit } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useNotification } from "../../Notification";
import { useFetchPricingsOrganization } from "../../../services/fetchPricings";

export default function ContractPricingView(props) {
  const { setEditablePricing } = props;

  const theme = useTheme();
  const { user } = useContext(AppContext);
  const showCSV = user?.role === "ORGANIZATION_ADMIN";
  const [selectedExternalBusiness, setSelectedExternalBusiness] =
    useState(null);
  const [csvLoading, setCsvLoading] = useState(false);
  const [addedPricings, setAddedPricings] = useState([]);
  const [notAddedPricings, setNotAddedPricings] = useState([]);
  const [showAddedPricingsDialog, setShowAddedPricingsDialog] = useState(false);
  const notification = useNotification();
  const { externalPricings, pricingsMutate, pricingsLoading } =
    useFetchPricingsOrganization("pricingListDialog");
  const mobileViewPort = theme.breakpoints.down("sm");
  const inputRef = useRef(null);
  const handleFileSelect = async (event) => {
    try {
      const file = event.target.files[0];

      if (file) {
        setCsvLoading(true);
        setShowAddedPricingsDialog(true);
        const reader = new FileReader();
        reader.onload = async (e) => {
          const csvContent = e.target.result;
          const result = await API.graphql(
            graphqlOperation(createPricingsFromCsv, {
              csvData: csvContent,
              orgExternalBusinessId: selectedExternalBusiness.id,
            })
          );
          try {
            const response = JSON.parse(
              result.data.createPricingsFromCsv.message
            );
            setAddedPricings(response.addedPricings);
            setNotAddedPricings(response.notAddedPricings);
          } catch (error) {
            if (error instanceof SyntaxError) {
              notification.show("CSV ei ole oikeassa formaatissa.", "warning");
              console.error("JSON data is not in the expected format.");
            } else {
              console.error("Error while parsing JSON data:", error);
            }
            setCsvLoading(false);
            return;
          }

          /*    fetchPricings(); */
          setCsvLoading(false);
        };
        reader.onerror = (e) => {
          console.error("FileReader error:", e.target.error);
          setCsvLoading(false);
        };
        reader.readAsText(file);
      } else {
        console.error("Tiedostoa ei valittu");
      }
    } catch (e) {
      console.log(e);
      setCsvLoading(false);
    }
  };

  const externalBusinessList = useMemo(() => {
    const businesses = user?.externalBusinessByOrg?.items.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    if (!businesses || businesses.length === 0) {
      return [];
    } else {
      return [...businesses];
    }
  }, [user?.externalBusinessByOrg]);

  // First, create a mapping of externalBusinessId to their names.
  const externalBusinessNamesMap = {};
  externalBusinessList?.forEach((business) => {
    externalBusinessNamesMap[business.id] = business.name;
  });

  // Next, group pricings by external business name.
  const pricingsByBusiness = {};
  externalPricings?.forEach((pricing) => {
    const businessName =
      externalBusinessNamesMap[pricing.orgExternalBusinessId];
    if (businessName) {
      if (!pricingsByBusiness[businessName]) {
        pricingsByBusiness[businessName] = [];
      }
      pricingsByBusiness[businessName].push(pricing);
    }
  });

  const extBusinessRows = useMemo(() => {
    const rows = externalPricings.filter(
      (pricing) =>
        pricing.orgExternalBusinessId === selectedExternalBusiness?.id
    );

    return rows;
  }, [externalPricings, selectedExternalBusiness?.id]);

  console.log("extBusinessRows", extBusinessRows);
  console.log("selectedExternalBusiness", selectedExternalBusiness);

  const extBusinessColumns = [
    /*     {
      field: "pricingName",
      headerName: "Hinnasto",
      flex: 1,
      minWidth: 150,
    }, */
    {
      field: "name",
      headerName: "Nimi",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "pricingHour",
      headerName: "Tunti",
      flex: 0.5,
      minWidth: 150,
      type: "number",
      valueGetter: (params) => {
        return `${centsToLocalString(
          params.row?.elements?.find((e) => e?.duration === "h0")?.price,
          true
        )}`;
      },
    },

    {
      field: "pricingDay",
      headerName: "Vuorokausi",
      type: "number",
      flex: 0.5,
      minWidth: 150,
      valueGetter: (params) => {
        return `${centsToLocalString(
          params.row?.elements?.find((e) => e?.duration === "d0")?.price,
          true
        )}`;
      },
    },
    {
      field: "deductible",
      headerName: "Omavastuu",
      type: "number",
      flex: 0.5,
      valueGetter: (params) => {
        return `${centsToLocalString(params?.row?.deductible, true)}`;
      },
    },
    {
      field: "VAT",
      headerName: "Verokanta",
      flex: 0.5,
      type: "number",
      valueGetter: (params) => {
        return `${params.row?.tax} %`;
      },
    },

    {
      field: "editPricing",
      headerName: "",
      width: 70,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => setEditablePricing(params.row)}>
            <Edit />
          </IconButton>
        );
      },
    },
  ];

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Stack direction="column" flex={1}>
        <Stack
          direction={mobileViewPort ? "column" : "row"}
          justifyContent={mobileViewPort ? "center" : "flex-end"}
          alignItems={mobileViewPort ? "flex-start" : "center"}
          sx={{ padding: mobileViewPort ? "10px" : undefined }}
        >
          {showCSV && (
            <Stack
              alignItems="center"
              direction={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box minWidth={"220px"}>
                <FormControl fullWidth>
                  <InputLabel id="select-contract-customer">
                    Valitse sopimusasiakas
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Valitse sopimusasiakas"
                    key={selectedExternalBusiness?.id || ""}
                    value={selectedExternalBusiness?.name || ""}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const selectedOption = externalBusinessList?.find(
                        (option) => option.name === selectedValue
                      );
                      setSelectedExternalBusiness(selectedOption);
                    }}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: "300px" } },
                    }}
                  >
                    <MenuItem
                      key="select-business"
                      value="Valitse sopimusasiakas"
                      disabled
                      sx={{ color: "black" }}
                    >
                      Valitse sopimusasiakas
                    </MenuItem>
                    {externalBusinessList?.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: "150px " }}>
                <input
                  type="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  accept=".csv"
                  onChange={handleFileSelect}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    selectedExternalBusiness?.id === "no-business" ||
                    selectedExternalBusiness?.id === "select-business"
                  }
                  onClick={handleButtonClick}
                  startIcon={<UploadFileIcon />}
                  sx={{ height: "56px", textTransform: "none" }}
                >
                  Lataa CSV
                </Button>
              </Box>
            </Stack>
          )}
        </Stack>
        <Box sx={{ maxHeight: "500px" }}>
          <DataGridPro
            loading={pricingsLoading}
            //  getRowId={(row) => row.key}
            sx={{
              //   borderColor: "transparent",
              borderRadius: "14px",
              overflow: "hidden",
              height: "500px",
              /*         fontFamily: "Public Sans, sans-serif",
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        }, */
              "& .MuiDataGrid-columnHeadersInner": {
                backgroundColor: "#f7f7f8",
                color: "#4e536c",
                fontFamily: "Sofia Pro",
                fontSize: "16px",
                // boxShadow: theme.shadows[4],
              },
            }}
            rows={extBusinessRows}
            columns={extBusinessColumns}
            pagination
            pageSize={10}
            rowsPerPageOptions={[2]}
            experimentalFeatures={{ newEditingApi: true }}
            disableRowSelectionOnClick
            disableSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
          />
        </Box>
      </Stack>
      <AddedPricingsDialog
        open={showAddedPricingsDialog}
        onClose={() => {
          setShowAddedPricingsDialog(false);

          pricingsMutate();
        }}
        addedPricings={addedPricings}
        csvLoading={csvLoading}
        notAddedPricings={notAddedPricings}
      />
    </>
  );
}

function AddedPricingsDialog({
  open,
  onClose,
  addedPricings,
  csvLoading,
  notAddedPricings,
  errorMsg,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" height={1500}>
      <DialogContent>
        {csvLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : !!errorMsg ? (
          <Typography variant="h6">{errorMsg}</Typography>
        ) : (
          <>
            <Typography variant="h6">Lisätty Hinnastot</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nimi</TableCell>
                  <TableCell>Tuntihinta</TableCell>
                  <TableCell>Päivähinta</TableCell>
                  <TableCell>Veroprosentti</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedPricings.map((pricing, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{pricing.name}</TableCell>
                    <TableCell>
                      {(pricing.hourly / 100).toFixed(2)} {getCurrencyUnit()}
                    </TableCell>
                    <TableCell>
                      {(pricing.daily / 100).toFixed(2)} {getCurrencyUnit()}
                    </TableCell>
                    <TableCell>{pricing.tax}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography mt={2} variant="h6">
              Lisäämättömät Hinnastot
            </Typography>
            <Typography variant="subtitle2" fontStyle="italic">
              (kategoria puuttuu)
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nimi</TableCell>
                  <TableCell>Tuntihinta</TableCell>
                  <TableCell>Päivähinta</TableCell>
                  <TableCell>Veroprosentti</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notAddedPricings.map((pricing, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{pricing.name}</TableCell>
                    <TableCell>
                      {(pricing.hourly / 100).toFixed(2)} {getCurrencyUnit()}
                    </TableCell>
                    <TableCell>
                      {(pricing.daily / 100).toFixed(2)} {getCurrencyUnit()}}
                    </TableCell>
                    <TableCell>{pricing.tax}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
}
