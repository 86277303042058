import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import InvoiceItemsTable from "./InvoiceItemsTable";
import { useFormikContext } from "formik";
import { API, graphqlOperation } from "aws-amplify";
import InnovoiceCreditDialog from "./InnovoiceCreditDialog";

import PaymentEventsTable from "./PaymentEventsTable";
import DialogComponent from "../../../../../../../DialogComponent";
import { useNotification } from "../../../../../../../Notification";
import { AppContext } from "../../../../../../../../AppContext";
import { createPaymentEvent } from "../../../../../../../../graphql/mutations";
import {
  getCurrencyUnit,
  parseToCents,
} from "../../../../../../../../shared/money";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../../atoms/reservationDataAtom";
import { devStaging } from "../../../../../../../../features";
import ReceiptDialog from "./ReceiptDialog";

const ActionButtons = ({
  setShowInvoiceDialog,
  invoiceItems,
  invoiceItemsSum,
  unpaidAmount,
  invoicesMutate,
  invoicesLoading,
  paymentEventsMutate,
  paymentEventsLoading,
  combinedEvents,
}) => {
  const { user } = useContext(AppContext);
  const { values } = useFormikContext();
  const reservationData = useAtomValue(reservationDataAtom);
  const notification = useNotification();

  const { business } = useContext(AppContext);

  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [showReceiptDialog, setShowReceiptDialog] = useState(false);
  const [showInnovoiceCreditDialog, setShowInnovoiceCreditDialog] =
    useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("CARD");
  const [paymentError, setPaymentError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRowClick = (amount) => {
    setPaymentAmount((amount / 100).toFixed(2).replace(".", ","));
  };

  const handlePaymentDialogOpen = () => {
    // If unpaid less than zero, default to zero
    setPaymentAmount(
      unpaidAmount < 0
        ? (0).toFixed(2).replace(".", ",")
        : (unpaidAmount / 100).toFixed(2).replace(".", ",")
    );
    setShowPaymentDialog(true);
  };

  const handlePaymentDialogClose = () => {
    setPaymentAmount("");
    setShowPaymentDialog(false);
  };

  const handleInnovoiceCreditDialogOpen = () => {
    setShowInnovoiceCreditDialog(true);
  };

  const handleInnovoiceCreditDialogClose = () => {
    setShowInnovoiceCreditDialog(false);
  };

  const handleAcceptPayment = async () => {
    setLoading(true);
    const cents = parseToCents(paymentAmount);
    if (cents === null || paymentAmount.includes(".")) {
      setPaymentError("Anna hinta muodossa '123,45'", "error");
      setLoading(false);
      return;
    } else {
      setPaymentError("");
    }

    let paymentEvent = {
      reservationId: reservationData.id,
      organizationId: user.organizationId,
      channel: "MANUAL",
      paymentTotal: cents,
      paymentMethod: paymentMethod,
      createdByUserID: user.id,
      createdByUserName: user.name,
    };

    switch (user.role) {
      case "ORGANIZATION_ADMIN":
        paymentEvent.orgBusinessId = business.id;
        break;
      case "BUSINESS_ADMIN":
        paymentEvent.orgBusinessId = business.id;
        break;
      case "EXTERNAL_BUSINESS_ADMIN":
        paymentEvent.externalBusinessId = user.externalBusinessId;
        break;
      default:
        console.error("Unsupported role for payment processing.");
        setLoading(false);
        return;
    }

    try {
      await API.graphql(
        graphqlOperation(createPaymentEvent, { input: paymentEvent })
      );
      await paymentEventsMutate();
      notification.show("Maksutapahtuma onnistui.", "success");
    } catch (error) {
      console.error("Error creating payment event:", error);
      notification.show(
        "Virhe luotaessa maksutapahtumaa: " + error.message,
        "error"
      );
    } finally {
      handlePaymentDialogClose();
      setLoading(false);
    }
  };

  const isArchived = reservationData.archivedAt;
  const actionStatus = isActionDisabled(user, isArchived);

  return (
    <>
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant="contained"
            color="success"
            onClick={handlePaymentDialogOpen}
            disabled={!reservationData?.id || isArchived}
            sx={{ width: "100%", height: 50 }}
          >
            Vastaanota maksu
          </Button>
        </Grid>
        {business?.id && (
          <Grid item xs={6} sm={4} md={2}>
            <Button
              variant="contained"
              color="info"
              onClick={handleInnovoiceCreditDialogOpen}
              sx={{ width: "100%", height: 50 }}
              disabled={actionStatus}
            >
              Rahoituskysely
            </Button>
          </Grid>
        )}
        {reservationData?.type !== "INSURANCE" && (
          <Grid item xs={6} sm={4} md={2}>
            <Button
              variant="contained"
              onClick={(e) => {
                setShowInvoiceDialog({ type: "FULL" });
              }}
              disabled={
                reservationData?.type === "INSURANCE" ||
                !reservationData?.id ||
                actionStatus
              }
              sx={{ width: "100%", height: 50 }}
            >
              Lasku {values?.isBroker ? "agentille" : "asiakkaalle"}
            </Button>
          </Grid>
        )}
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant="contained"
            onClick={(e) => {
              setShowInvoiceDialog({ type: "EXTRA" });
            }}
            disabled={!reservationData?.id || actionStatus}
            sx={{ width: "100%", height: 50 }}
          >
            Lisälaskutus
          </Button>
        </Grid>
        {reservationData?.channel === "AUTOLIITTO" &&
          reservationData?.type !== "INSURANCE" && (
            <Grid item xs={6} sm={4} md={2}>
              <Button
                variant="contained"
                onClick={(e) => {
                  setShowInvoiceDialog({ type: "AUTOLIITTO" });
                }}
                sx={{ width: "100%", height: 50 }}
              >
                Lasku autoliitolle
              </Button>
            </Grid>
          )}
        {reservationData?.type === "INSURANCE" && (
          <Grid item xs={6} sm={4} md={2}>
            <Button
              variant="contained"
              onClick={(e) => {
                if (!values.insuranceCompany) {
                  return notification.show("Valitse ensin vakuutusyhtiö");
                }
                if (!values.insuranceData?.billingPermitCode) {
                  return notification.show("Aseta laskutuslupatunnus");
                }
                setShowInvoiceDialog({ type: "INSURANCE_COMPANY" });
              }}
              disabled={reservationData?.type !== "INSURANCE"}
              sx={{ width: "100%", height: 50 }}
            >
              Lasku vakuutusyhtiölle
            </Button>
          </Grid>
        )}
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant="contained"
            onClick={() => setShowReceiptDialog(true)}
            disabled={
              !reservationData?.id ||
              reservationData?.type === "INSURANCE" ||
              isArchived
            }
            sx={{ width: "100%", height: 50 }}
          >
            Kuitti
          </Button>
        </Grid>
      </Grid>
      <DialogComponent
        open={showPaymentDialog}
        dialogClose={handlePaymentDialogClose}
        fullWidth
        maxWidth="md"
      >
        <Box padding={4}>
          <Grid container spacing={2} alignItems="center" direction="column">
            <Grid item>
              <InvoiceItemsTable
                invoiceItems={invoiceItems}
                invoiceItemsSum={invoiceItemsSum}
                onRowClick={handleRowClick}
              />
              <PaymentEventsTable
                setShowInvoiceDialog={setShowInvoiceDialog}
                unpaidAmount={unpaidAmount}
                showActions={false}
                invoicesMutate={invoicesMutate}
                invoicesLoading={invoicesLoading}
                paymentEventsMutate={paymentEventsMutate}
                paymentEventsLoading={paymentEventsLoading}
                combinedEvents={combinedEvents}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                Maksun summa
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                placeholder="Syötä summa"
                error={Boolean(paymentError)}
                helperText={paymentError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencyUnit()}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel id="payment-method-radio-buttons">
                  Maksutapa
                </FormLabel>
                <RadioGroup
                  aria-labelledby="payment-method-radio-buttons"
                  name="paymentMethod-radio-buttons-group"
                  value={paymentMethod}
                  onChange={(event) => setPaymentMethod(event.target.value)}
                >
                  <FormControlLabel
                    value="CASH"
                    control={<Radio />}
                    label="Käteinen"
                  />
                  <FormControlLabel
                    value="CARD"
                    control={<Radio />}
                    label="Korttimaksu"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handlePaymentDialogClose}
                    color="error"
                  >
                    Peruuta
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleAcceptPayment}
                    color={"success"}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Vastaanota maksu"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogComponent>
      <InnovoiceCreditDialog
        open={showInnovoiceCreditDialog}
        onClose={handleInnovoiceCreditDialogClose}
        orgBusinessId={business?.id}
        initialSsn=""
      />
      {showReceiptDialog && (
        <ReceiptDialog
          open={showReceiptDialog}
          onClose={() => setShowReceiptDialog(false)}
          reservationId={reservationData.id}
        />
      )}
    </>
  );
};

const isActionDisabled = (user, isArchived) => {
  const isDevelopment = devStaging(process.env.REACT_APP_ENV);
  const allowedOrganizations = [
    "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1", // Scandia Rent
  ];

  if (isArchived) {
    return true;
  }

  if (isDevelopment) {
    return false;
  }
  return allowedOrganizations.some((org) => org !== user?.organizationId);
};

export default ActionButtons;
