// @ts-ignore
import { getGlobalLocalizationConfig } from "../shared/localizationConfig";

const VAT_VALUES_SV = {
  0: "0",
  6: "6",
  12: "12",
  25: "25",
};

const VAT_VALUES_NO = {
  0: "0",
  12: "12",
  15: "15",
  25: "25",
};

const VAT_VALUES_FI = {
  0: "0",
  10: "10",
  14: "14",
  24: "24",
  25.5: "25.5",
};

const DEFAULT_VAT_VALUE_FI = 25.5;
const DEFAULT_VAT_VALUE_SV = 25;
const DEFAULT_VAT_VALUE_NO = 25;

// Return VAT values and default VAT value for a currency
export const getVatConfig = () => {
  const { currency } = getGlobalLocalizationConfig();
  let VAT_VALUES, DEFAULT_VAT_VALUE;

  switch (currency) {
    case "SEK":
      VAT_VALUES = VAT_VALUES_SV;
      DEFAULT_VAT_VALUE = DEFAULT_VAT_VALUE_SV;
      break;
    case "NOK":
      VAT_VALUES = VAT_VALUES_NO;
      DEFAULT_VAT_VALUE = DEFAULT_VAT_VALUE_NO;
      break;
    default:
      VAT_VALUES = VAT_VALUES_FI;
      DEFAULT_VAT_VALUE = DEFAULT_VAT_VALUE_FI;
      break;
  }

  return {
    VAT_VALUES,
    DEFAULT_VAT_VALUE,
  };
};
