/* Unified way of setting items for Reservation queries */
const reservationItems = `
    id
    shortId
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    companyId
    reviewId
    reservationVehicleId
    language
    startLocation
    startLocationID
    returnLocation
    returnLocationID
    pricingCategoryId
    customImportDate
    customerNotes
    reservationVehicles {
      id
      price
      tax
      name
      registrationPlate
      deductible
      includedDistance
      powerType
      mileage
      startMileage
      endMileage
      extraInfo
      damages
      hideExport
      createdAt
      categoryName
    }
    wildCardVehicles {
      name
      price
      deductible
      categoryId
      quantity
    }
    createdAt
    updatedAt
    startTime
    returnTime
    endTime
    name
    address
    billingRef
    postalCode
    city
    phone
    email
    ssn
    rentalNotes
    internalNotes
    externalNotes
    outOfCommission
    vehiclePrice
    additionalServices {
      key
      price
      details
      quantity
      type
      deductibleValue
      groupFields
      tax
      enableNotification
      description
      category
    }
    driverSSN
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    additionalDriverSSN
    billPayment
    isCompany
    carStatus
    rentalStatus
    innovoiceStatus
    customerBrokerId
    customerCompanyId
    customerPersonalId
    companyName
    companyPostalCode
    companyCity
    companyBusinessId
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    paymentToken
    npsStatus
    paymentStatus
    paymentData {
      paymentTotal
      paymentTodo
      depositPending
      deposit
    }
    signStatus
    signDocumentId
    signerName
    reviewStatus
    authStatus
    authTime
    completeBefore
    mustSign
    livionData {
      id
      pincode
      keyId
      name
      registrationPlate
    }
    voucherData {
      name
      code
      amount
    }
    customerCarRegistrationPlate
    insuranceData {
      customerContact
      urgent
      customerCarModel
      customerCarMaker
      product
      coveragePercentage
      compensatableDays
      billingPermitCode
      coverageDuration
      providerCompany
      providerBusinessId
      claimAdjusterName
      claimAdjusterEmail
      receipt
      replacementVehicleClass
      claimId
      vatDeductible
      deliveryCosts
      returnCosts
    }
    initialClassInfo {
      categoryName
      hanselClass
      categoryId
    }
    externalData {
      orderId
      orderReference
      serviceAgreementName
      operatorName
      orderInfo
      billingPayer
      billingDescription
      customerVehicle {
        brand
        model
        registrationPlate
      }
      incidentLocation {
        description
        address
        city
        zipCode
      }
      repairShop {
        description
        address
        city
        zipCode
      }
    }
    enableRegistrationPlate
    disablePricesInPrintouts
    hanselData {
      contractNro
      customerInvoiceId
      customerInvoiceDate
      hanselAgent
      hanselClass
      customerId
      subContractorNumber
      subContractorName
      suplierProductId
      suplierProductName
      quantity
      price
      totalPrice
      priceNoVAT
      additionalInfo1
      additionalInfo2
      additionalInfo3
      desc
    }
    channel
    type
    status
    reservationStatusInfo {
      deliveryTimestamp
      deliveryAddress
      deliveryZipcode
      deliveryCity
      deliveryCountry
      deliveryDescription
      returnTimestamp
      returnAddress
      returnZipcode
      returnCity
      returnCountry
      returnDescription
      cancelReason
    }
    initialClassInfo {
      categoryId
      categoryName
      hanselClass
      __typename
    }
    orgInvoiceCustomer {
      name
      businessId
      __typename
    }
    invoiceIds

`;

const reservationFragment = /* GraphQL */ `
  fragment ReservationFragment on Reservation {
    ${reservationItems}
  }
`;

const modelReservationConnectionFragment = /* GraphQL */ `
  fragment ModelReservationConnectionFragment on ModelReservationConnection {
    items {
      ${reservationItems}
    }
    nextToken
  }
`;

const searchableReservationFragment = /* GraphQL */ `
  fragment SearchableReservationConnectionFragment on SearchableReservationConnection {
    items {
      ${reservationItems}
    }
    nextToken
  }
`;

export const getReservationOffers = /* GraphQL */ `
  query GetReservationOffers(
    $companyIds: [ID!]!
    $startTime: AWSDateTime!
    $returnTime: AWSDateTime!
    $timeFlexibility: Int!
    $disableCategoryDefaultsStacking: Boolean
  ) {
    getReservationOffers(
      companyIds: $companyIds
      startTime: $startTime
      returnTime: $returnTime
      timeFlexibility: $timeFlexibility
      disableCategoryDefaultsStacking: $disableCategoryDefaultsStacking
    ) {
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        createdAt
        updatedAt
        includedDistance
        deductible
        extraInfo
        additionalFields {
          key
          value
          valueT {
            en
            fi
            sv
          }
        }
        category {
          id
          name
          typeId
        }
        image
        orderNumber
        useStartReturnTimes
        startReturnTimes {
          start
          return
        }
        customStartReturns {
          priority
          startReturnInterval {
            start
            end
          }
          startReturnTimes {
            start
            return
          }
        }
      }
      startTime
      returnTime
      vehicleOfferPrice
      additionalServiceOffers {
        key
        offerPrice
        deductibleValue
      }
      freeTimes {
        returnTimes {
          start
          end
        }
        firstStartTime
        lastStartTime
        lastReturnTime
      }
      reservedTimes {
        start
        end
      }
      availableInCategory
      useVehicleTimes
      disableOffHourReturn
    }
  }
`;

export const listVehiclesByBusinessWithAdditionalServices = /* GraphQL */ `
  query VehicleByOrgBusinessId(
    $orgBusinessId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleByOrgBusinessId(
      orgBusinessId: $orgBusinessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        name
        registrationPlate
        damages
        damageRecords {
          normalizedX
          normalizedY
          label
          description
          createdAt
        }
        deductible
        powerType
        includedDistance
        mileage
        orderNumber
        additionalFields {
          key
          value
          valueT {
            en
            fi
            sv
          }
        }
        additionalServices {
          items {
            id
            key
            vehicleId
            pricingId
            deductibleValue
            createdAt
            updatedAt
          }
          nextToken
        }
        image
        createdAt
        updatedAt
        pricing {
          id
          name
          group
          createdAt
          updatedAt
        }
        category {
          id
          typeId
          name
          orderNumber
          additionalServices {
            items {
              id
              key
              pricingId
            }
            nextToken
          }
        }
        useCategoryDefaults
        useStartReturnTimes
        startReturnTimes {
          start
          return
        }
        startReturnInterval {
          start
          end
        }
        hideExport
        extraInfo
        extraToConfirmation
        livionKeyId
        disableOnline
        activeRange {
          start
          end
          __typename
        }
      }
      nextToken
    }
  }
`;

export const listVehiclesByGroupWithAdditionalServices = /* GraphQL */ `
  query VehicleByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        name
        registrationPlate
        damages
        damageRecords {
          normalizedX
          normalizedY
          label
          description
          createdAt
        }
        deductible
        powerType
        includedDistance
        mileage
        orderNumber
        additionalFields {
          key
          value
          valueT {
            en
            fi
            sv
          }
        }
        additionalServices {
          items {
            id
            key
            vehicleId
            pricingId
            deductibleValue
            createdAt
            updatedAt
          }
          nextToken
        }
        image
        createdAt
        updatedAt
        pricing {
          id
          name
          group
          createdAt
          updatedAt
        }
        category {
          id
          typeId
          name
          orderNumber
          additionalServices {
            items {
              id
              key
              pricingId
            }
            nextToken
          }
        }
        useCategoryDefaults
        useStartReturnTimes
        startReturnTimes {
          start
          return
        }
        startReturnInterval {
          start
          end
        }
        hideExport
        extraInfo
        extraToConfirmation
        livionKeyId
        disableOnline
        activeRange {
          start
          end
          __typename
        }
      }
      nextToken
    }
  }
`;

export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        companyId
        name
        registrationPlate
        damages
        deductible
        powerType
        includedDistance
        mileage
        orderNumber
        additionalFields {
          key
          value
          valueT {
            en
            fi
            sv
          }
        }
        additionalServices {
          items {
            id
            key
            vehicleId
            pricingId
            deductibleValue
            createdAt
            updatedAt
          }
          nextToken
        }
        image
        createdAt
        updatedAt
        pricing {
          id
          name
          group
          createdAt
          updatedAt
        }
        category {
          id
          typeId
          image
          name
          orderNumber
          backgroundColor
          minuteBuffer
          reservationBlockFromNow
          pricing {
            id
            name
            group
            createdAt
            updatedAt
          }
          additionalServices {
            items {
              id
              key
              pricingId
            }
            nextToken
          }
        }
        useCategoryDefaults
        useStartReturnTimes
        startReturnTimes {
          start
          return
        }
        startReturnInterval {
          start
          end
        }
        hideExport
        extraInfo
        extraToConfirmation
        livionKeyId
        activeRange {
          start
          end
          __typename
        }
      }
      nextToken
      __typename
    }
  }
`;

export const companyByBusiness = /* GraphQL */ `
  query CompanyByBusiness(
    $orgBusinessId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByBusiness(
      orgBusinessId: $orgBusinessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        deliveryBufferHours
        deliveryFee
        displayName
        disableReservationCategoryIds
        email
        address
        postalCode
        city
        orderNumber
        locationColor
        orderNumberDealer
        offHourAvailabilityFee
        offHourAvailability
        phone
        businessId
        termsUrl
        timezone
        currency
        locale
        weeklyBusinessHours {
          openAt
          closeAt
        }
        nameInEmail
        signConfirmationEmail
        statusLinkMsg
        confirmationMsg
        feedbackMsg
        createdAt
        updatedAt
        categoryDynamicPricings {
          categoryId
          pricingId
          percentage
        }
        businessHoursExceptions {
          nextToken
        }
        vehicles {
          nextToken
        }
        categoriesByOrg {
          items {
            id
            typeId
            image
            name
            orderNumber
            backgroundColor
            minuteBuffer
            reservationBlockFromNow
            pricing {
              id
              name
              group
              createdAt
              updatedAt
            }
            additionalServices {
              items {
                id
                key
                pricingId
              }
              nextToken
            }
          }
          nextToken
        }
        livionDeviceId
        livionDeviceType
        enableRegistrationPlate
        enableCart
        enableVehicleStartReturnTimes
        enableReservationDeposit
        enableMustSign
        enablePaymentOnlineOnly
        enableNPS
        contractSettings {
          name
          enabled
        }
        contractSettingsJSON
        reservationCancellation {
          cancellationEnabled
          daysBeforeStart
        }
        reservationLinkExpirationHours
        reservationDeposit {
          amount
          reminderBefore
          paymentBefore
        }
        receiptInfo {
          enabled
          name
          address
          city
          postalCode
        }
        features {
          enableNPS
          enableVismaPay
          enableReservationDialogV2
        }
      }
      nextToken
      __typename
    }
  }
`;

export const companyByGroup = /* GraphQL */ `
  query CompanyByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        deliveryBufferHours
        deliveryFee
        disableReservationCategoryIds
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        offHourAvailabilityFee
        offHourAvailability
        phone
        businessId
        termsUrl
        timezone
        currency
        locale
        weeklyBusinessHours {
          openAt
          closeAt
        }
        nameInEmail
        signConfirmationEmail
        statusLinkMsg
        confirmationMsg
        feedbackMsg
        createdAt
        updatedAt
        contractSettingsJSON
        contractSettings {
          name
          enabled
        }
        businessHoursExceptions {
          nextToken
        }
        vehicles {
          nextToken
        }
        categories {
          items {
            id
            typeId
            image
            name
            orderNumber
            backgroundColor
            minuteBuffer
            reservationBlockFromNow
            pricing {
              id
              name
              group
              createdAt
              updatedAt
            }
            additionalServices {
              items {
                id
                key
                pricingId
              }
              nextToken
            }
          }
          nextToken
        }
        categoriesByOrg {
          items {
            id
            typeId
            image
            name
            orderNumber
            backgroundColor
            minuteBuffer
            reservationBlockFromNow
            pricing {
              id
              name
              group
              createdAt
              updatedAt
            }
            additionalServices {
              items {
                id
                key
                pricingId
              }
              nextToken
            }
          }
          nextToken
        }
        livionDeviceId
        livionDeviceType
        enableRegistrationPlate
        enableCart
        enableVehicleStartReturnTimes
        enableReservationDeposit
        enableMustSign
        enablePaymentOnlineOnly
        enableNPS
        reservationCancellation {
          cancellationEnabled
          daysBeforeStart
        }
        reservationLinkExpirationHours
        reservationDeposit {
          amount
          reminderBefore
          paymentBefore
        }
        receiptInfo {
          enabled
          name
          address
          city
          postalCode
        }
        features {
          enableNPS
          enableVismaPay
          enableReservationDialogV2
        }
      }
      nextToken
    }
  }
`;

export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        deliveryFee
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        currency
        locale
        currency
        locale
        offHourAvailabilityFee
        offHourAvailability
        weeklyBusinessHours {
          openAt
          closeAt
        }
        nameInEmail
        signConfirmationEmail
        statusLinkMsg
        confirmationMsg
        feedbackMsg
        createdAt
        updatedAt
        businessHoursExceptions {
          nextToken
        }
        vehicles {
          nextToken
        }
        categories {
          items {
            id
            typeId
            image
            name
            orderNumber
            backgroundColor
            minuteBuffer
            reservationBlockFromNow
            pricing {
              id
              name
              group
              createdAt
              updatedAt
            }
            additionalServices {
              items {
                id
                key
                pricingId
              }
              nextToken
            }
          }
          nextToken
        }
        categoriesByOrg {
          items {
            id
            typeId
            image
            name
            orderNumber
            backgroundColor
            minuteBuffer
            reservationBlockFromNow
            pricing {
              id
              name
              group
              createdAt
              updatedAt
            }
            additionalServices {
              items {
                id
                key
                pricingId
              }
              nextToken
            }
          }
          nextToken
        }
        livionDeviceId
        livionDeviceType
        enableRegistrationPlate
        enableCart
        enableVehicleStartReturnTimes
        enableReservationDeposit
        enableMustSign
        enablePaymentOnlineOnly
        enableNPS
        reservationCancellation {
          cancellationEnabled
          daysBeforeStart
        }
        reservationLinkExpirationHours
        reservationDeposit {
          amount
          reminderBefore
          paymentBefore
        }
        receiptInfo {
          enabled
          name
          address
          city
          postalCode
        }
        features {
          enableNPS
          enableVismaPay
          enableReservationDialogV2
        }
      }
      nextToken
    }
  }
`;

export const searchCustomers = /* GraphQL */ `
  query SearchReservations(
    $filter: SearchableReservationFilterInput
    $sort: SearchableReservationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReservations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        group
        companyName
        companyId
        createdAt
        name
        address
        postalCode
        city
        phone
        email
        customerCarRegistrationPlate
        insuranceData {
          billingPermitCode
        }
      }
      nextToken
      total
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser(
    $id: ID!
    $pricingFilter: ModelPricingFilterInput
    $includeExtPricing: Boolean!
  ) {
    getUser(id: $id) {
      id
      organizationId
      orgBusinessId
      orgBusinessIds
      externalBusinessId
      name
      email

      organization {
        id
        name
        features {
          enableNPS
        }
      currency
      locale
      }
      externalBusiness {
        id
        organizationId
        type
        name
        hanselId
        companies {
          items {
            id
            name
            postalCode
            city
          }
          nextToken
          __typename
        }
        availableCompanyIds
        availableOrgBusinessIds
        insuranceProducts {
          name
          coveragePercentage
          compensatableDays
          billingPermitCode
          __typename
        }
        insuranceRegionalCompanies {
          name
          companyName
          businessId
          address
          postalCode
          postalArea
          email
          innovoiceId
          __typename
        }
        insuranceCompensatableTimes
        pricings {
          items {
            id
            name
            externalBusinessCategoryAssociation
          }
          nextToken
          __typename
        }
        __typename
      }
      externalBusinessByOrg {
        items {
          id
          organizationId
          type
          name
          insuranceCompensatableTimes
          availableOrgBusinessIds
          insuranceProducts {
            name
            coveragePercentage
            compensatableDays
            billingPermitCode
            __typename
          }
          insuranceRegionalCompanies {
            name
            companyName
            businessId
            address
            postalCode
            postalArea
            email
            innovoiceId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      businessByOrg {
        items {
          id
          organizationId
          pickupLocations {
            id
            name
            locationColor
            companyId
          }
          categoryDynamicPricings {
            percentage
            categoryId
            pricingId
          }
          insuranceCoverageZones {
            start
            end
          }
          group
          email
          name
          phone
          address
          city
          createdAt
          updatedAt
          __typename
          telegramConnections {
            chatId
            name
            __typename
          }
          companies {
            items {
              id
              name
              deliveryBufferHours
              timezone
              currency
              locale
            }
            nextToken
            __typename
          }
        }
        nextToken
        __typename
      }

      categoriesByOrg {
        items {
          id
          group
          organizationId
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          reservationBlockFromNow
          typeId
          _removed
          oldTujausValue
          exampleModelName
          createdAt
          updatedAt
          __typename
          pricing {
            id
            name
            group
            createdAt
            updatedAt
          }
          additionalServices {
            items {
              id
              key
              pricingId
            }
            nextToken
          }
          externalBusinessPricings(filter: $pricingFilter)
            @include(if: $includeExtPricing) {
            items {
              id
              name
              orgExternalBusinessId
              externalBusinessCategoryAssociation
              elements {
                duration
                price
              }
              useNextDayPrice
              tax
              deductible
              reducedDeductible
              reducedDeductiblePrice
            }
            nextToken
          }
        }
        nextToken
        __typename
      }
      role
      permissions {
        userManagement
      }
    }
  }
`;

export const searchReservations = /* GraphQL */ `
  query SearchReservations(
    $filter: SearchableReservationFilterInput
    $sort: SearchableReservationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReservations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      ...SearchableReservationConnectionFragment
    }
  }
  ${searchableReservationFragment}
`;

export const getReservations = /* GraphQL */ `
  query GetReservations(
    $startTime: AWSDateTime!
    $returnTime: AWSDateTime
    $endTime: AWSDateTime
    $group: String!
    $queryMode: String!
  ) {
    getReservations(
      startTime: $startTime
      returnTime: $returnTime
      endTime: $endTime
      group: $group
      queryMode: $queryMode
    ) {
      ...ReservationFragment
    }
  }
  ${reservationFragment}
`;

export const listReservationsByStatus = /* GraphQL */ `
  query ListReservationsByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservationsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      ...ModelReservationConnectionFragment
    }
  }
  ${modelReservationConnectionFragment}
`;

export const categoryByOrganization = /* GraphQL */ `
  query CategoryByOrganization(
    $organizationId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryByOrganization(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeId
        image
        name
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        pricing {
          id
          name
          group
          createdAt
          updatedAt
        }
        additionalServices {
          items {
            id
            key
            pricingId
          }
          nextToken
        }
      }
      nextToken
      __typename
    }
  }
`;

export const categoryByGroup = /* GraphQL */ `
  query CategoryByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeId
        image
        name
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        pricing {
          id
          name
          group
          createdAt
          updatedAt
        }
        additionalServices {
          items {
            id
            key
            pricingId
          }
          nextToken
        }
      }
      nextToken
      __typename
    }
  }
`;
