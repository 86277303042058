import React, { useState, useEffect } from "react";

const LocaleCurrencyFormatter = () => {
  const [locale, setLocale] = useState("fi-FI");
  const [currency, setCurrency] = useState("EUR");
  const [input, setInput] = useState("");
  const [parsedCents, setParsedCents] = useState(null);
  const [formattedPrice, setFormattedPrice] = useState("");

  // Converts cents to a localized currency string
  const centsToLocalString = (cents, locale, currency) => {
    const whole = cents / 100;
    if (isNaN(whole)) {
      return "";
    }
    return whole.toLocaleString(locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Parses a localized string input into cents
  const parseToCents = (str, currency) => {
    function parseWhole(wholeStr) {
      const value = parseInt(wholeStr, 10);
      if (Number.isNaN(value)) {
        throw new Error("Parsing failed for: " + wholeStr);
      }
      return value;
    }

    function parseCents(centsStr) {
      if (centsStr == null || centsStr === "") {
        return 0;
      }
      if (centsStr.length === 1) {
        centsStr = centsStr + "0";
      }
      const value = parseInt(centsStr, 10);
      if (Number.isNaN(value)) {
        throw new Error("Parsing failed for: " + centsStr);
      }
      return value;
    }

    if (typeof str === "string") {
      str = str.replace(/\s+/g, ""); // Remove spaces
      str = str.replace(/[‐−–]/g, "-"); // Normalize negative signs
    }

    const fractionalCurrencies = ["EUR", "SEK", "NOK"];
    const hasFractionalPart = fractionalCurrencies.includes(currency);

    const re = new RegExp(
      `^(-?)(?<whole>[0-9]+)([,.](?<cents>[0-9]?[0-9]?))?$`
    );
    const match = re.exec(str);
    if (match) {
      const isNegative = match[1] === "-" ? -1 : 1;
      const whole = parseWhole(match.groups.whole);
      const cents = hasFractionalPart ? parseCents(match.groups.cents) : 0;

      return (whole * 100 + cents) * isNegative;
    } else {
      return null;
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);

    try {
      const cents = parseToCents(value, currency);
      setParsedCents(cents);

      const formatted = centsToLocalString(cents, locale, currency);
      setFormattedPrice(formatted);
    } catch (err) {
      setParsedCents(null);
      setFormattedPrice("");
      console.error(err.message);
    }
  };

  const handleLocaleChange = (e) => {
    const selectedLocale = e.target.value;
    setLocale(selectedLocale);
    if (selectedLocale === "fi-FI") setCurrency("EUR");
    if (selectedLocale === "sv-SE") setCurrency("SEK");
    if (selectedLocale === "nb-NO") setCurrency("NOK");
  };

  // Reformat the input whenever locale or currency changes
  useEffect(() => {
    if (input) {
      try {
        const cents = parseToCents(input, currency);
        setParsedCents(cents);

        const formatted = centsToLocalString(cents, locale, currency);
        setFormattedPrice(formatted);
      } catch (err) {
        setParsedCents(null);
        setFormattedPrice("");
        console.error(err.message);
      }
    }
  }, [locale, currency, input]);

  return (
    <div style={{ fontFamily: "Arial, sans-serif", margin: "20px" }}>
      <h2>Locale & Currency Formatter</h2>

      <label>
        Select Locale:
        <select value={locale} onChange={handleLocaleChange}>
          <option value="fi-FI">Finnish (EUR)</option>
          <option value="sv-SE">Swedish (SEK)</option>
          <option value="nb-NO">Norwegian (NOK)</option>
        </select>
      </label>

      <div style={{ marginTop: "20px" }}>
        <label>
          Enter Amount:
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Enter price (e.g., 1 234,56)"
            style={{ marginLeft: "10px", padding: "5px", width: "200px" }}
          />
        </label>
      </div>

      <div style={{ marginTop: "20px" }}>
        <strong>Parsed Cents:</strong>{" "}
        {parsedCents !== null ? parsedCents : "Invalid input"}
      </div>

      <div style={{ marginTop: "10px" }}>
        <strong>Formatted Price:</strong> {formattedPrice || "Invalid input"}
      </div>

      <div style={{ marginTop: "10px" }}>
        <strong>Current Locale:</strong> {locale}
      </div>

      <div style={{ marginTop: "10px" }}>
        <strong>Current Currency:</strong> {currency}
      </div>
    </div>
  );
};

export default LocaleCurrencyFormatter;
