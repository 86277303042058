import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { centsToLocalString } from "../../../../../shared/money";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { format } from "date-fns";

export default function HistoryItem({ reservation }) {
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const [open, setOpen] = useState(false);
  const { date: startDate, time: startTime } = useGetDate(
    reservation?.startTime
  );
  const { date: returnDate, time: returnTime } = useGetDate(
    reservation?.returnTime
  );
  const reservationName =
    reservation?.companyName || reservation?.name || reservation?.email;

  return (
    <React.Fragment>
      <ListItem>
        <ListItemButton
          onClick={() => setOpen((prevState) => !prevState)}
          dense
          sx={{ padding: 0 }}
        >
          <ListItemText
            disableTypography
            primary={
              <>
                {startDate} {startTime} - {returnDate} {returnTime}
              </>
            }
            secondary={
              <Typography sx={{ color: "grey" }}>{reservationName}</Typography>
            }
          />
          <ListItemSecondaryAction>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem>
          <Stack spacing={1} flex={1}>
            <Stack direction={"row"} spacing={1}>
              <Typography>
                <b>Luontipäivä:</b>
              </Typography>
              <Typography>
                {format(new Date(reservation?.createdAt), "dd.MM HH:mm")}
              </Typography>
            </Stack>
            <AdditionalServices services={reservation?.additionalServices} />

            <ReservationChannel channel={reservation?.channel} />
            <ReservationType type={reservation?.type} />
            <Box
              sx={{
                paddingBottom: "10px",
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => setEditReservationData(reservation)}
              >
                Avaa varaus
              </Button>
            </Box>
          </Stack>
        </ListItem>
      </Collapse>
    </React.Fragment>
  );
}

const ReservationChannel = ({ channel, type }) => {
  return (
    <Stack direction={"row"} spacing={1}>
      <Typography>
        <b>Varauskanava:</b>
      </Typography>
      <Typography>{EReservationChannels[channel]}</Typography>
      {type && <Typography> {EReservationTypes[type]}</Typography>}
    </Stack>
  );
};

const ReservationType = ({ type }) => {
  if (type)
    return (
      <Stack direction={"row"} spacing={1}>
        <Typography>
          <b>Varauksen tyyppi:</b>
        </Typography>
        <Typography>{EReservationTypes[type]}</Typography>
      </Stack>
    );
};

const AdditionalServices = ({ services }) => {
  if (services)
    return (
      <Box>
        <Typography>
          <b>Lisäpalvelut:</b>
        </Typography>
        <Stack spacing={1}>
          {services.map((service) => {
            return (
              <Stack key={service.key} direction={"row"} spacing={1}>
                <Typography>{service.description}</Typography>
                <Typography>
                  {centsToLocalString(service.price, true)}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Box>
    );
};

const useGetDate = (dateValue) => {
  const date = <span>{format(new Date(dateValue), "dd.MM.")}</span>;
  const time = <span>{format(new Date(dateValue), "HH:mm")}</span>;
  return { date: date, time: time };
};

const EReservationChannels = {
  DEALER: "Vuokraamo",
  DEALER_ORGANIZATION: "Organisaation varaus",
  DEALER_EXTERNAL: "Yritysportaali",
  CUSTOMER: "Online",
  SOS: "Hätäpalvelu",
  AUTOLIITTO: "Autoliitto",
};

const EReservationTypes = {
  INSURANCE: "Vakuutuskorvaus",
  HANSEL: "Hansel varaus",
};
