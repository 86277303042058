import { getVatConfig } from "~/configs/vat-values";

export const getDefaultInvoiceItem = () => {
  const { DEFAULT_VAT_VALUE } = getVatConfig();
  return {
    name: "",
    description: "",
    unitprice: "",
    quantity: 1,
    unit: "kpl",
    vat: DEFAULT_VAT_VALUE,
  };
};

export const defaultValues = {
  firstname: "",
  lastname: "",
  address: "",
  postalCode: "",
  city: "",
  email: "",
  phone: "",
  ssn: "",
  contact_person: "",
  companyName: "",
  companyBusinessId: "",

  // Read services from the Reservation, rest comes from the Invoice sent to insurance company
  items: [getDefaultInvoiceItem()],

  customer_type: "person",
  payment_terms: "30",
  delivery_date: new Date(),
  seller_reference: "",
  buyer_reference: "",
  e_invoice_operator: "",
  e_invoice_address: "",
  vat_included: 1,
  delivery_by: "mail",
  insuranceCompany: "",
  insuranceCoveragePercentage: 100,
  insuranceVatDeductible: false,
  vehicle_class: "",
};
