import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useMemo } from "react";
import { centsToLocalString, getCurrencyUnit } from "../shared/money";
import PriceField from "./PriceField";
import { FormGrid } from "./common/FormComponents";

const AdditionalServiceField = ({
  formik,
  defaultPrice,
  isDefaultPriceLoading,
  service,
  vehicleQuantity,
  ...props
}) => {
  const switchName = service.id + "Enabled";
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            {...props}
            name={switchName}
            checked={formik.values[switchName] || false}
            onChange={() => {
              // Set opposite boolean using setFieldValue to overcome handleChange bug introduced after MUI 5 upgrade.
              try {
                const old = !!formik.values[switchName];
                formik.setFieldValue(switchName, !old);
              } catch (e) {
                console.error(
                  "Failed to setFieldValue for additionalService. ",
                  e
                );
              }
              //formik.handleChange(); // For some reason this doesn't work after MUI upgrade.
            }}
            onBlur={formik.handleBlur}
          />
        }
        label={service.description}
      />
      {formik.values[switchName] && (
        <PriceField
          formik={formik}
          name={service.id}
          defaultPrice={defaultPrice}
          isDefaultPriceLoading={isDefaultPriceLoading}
          enableQuantity={service.enableQuantity}
          vehicleQuantity={vehicleQuantity}
        />
      )}
    </>
  );
};

export default function AdditionalServiceList({
  formik,
  categories,
  services,
  header,
  offerPrice,
  vehicleQuantity,
}) {
  const [servicesPerCat, setServicesPerCat] = useState([]);

  useMemo(() => {
    if (services) {
      const servicesForCategory = services
        .filter((s) => {
          return categories.includes(s.category);
        })
        .sort((s1, s2) => {
          if (categories.includes("distance")) {
            try {
              const r = /\d+/g;
              const num1 = parseInt(s1.description.match(r)[0]);
              const num2 = parseInt(s2.description.match(r)[0]);
              return num1 - num2;
            } catch (e) {
              // Handle non numerical eg. "Rajattomat kilometrit"
              return -1;
            }
          } else {
            return s1.description.localeCompare(s2.description);
          }
        });
      setServicesPerCat(servicesForCategory);
    }
  }, [services, categories]);

  return (
    <FormGrid>
      <Box>
        <Typography variant="h6">{header}</Typography>
      </Box>
      <Grid item container direction="column">
        {servicesPerCat.length > 0 ? (
          servicesPerCat.map((service) => {
            return (
              <Grid
                container
                direction="column"
                key={service.id}
                sx={{ marginLeft: "5px" }}
              >
                <AdditionalServiceField
                  formik={formik}
                  isDefaultPriceLoading={offerPrice === undefined}
                  defaultPrice={
                    offerPrice?.additionalServicePrices[service.id]?.offerPrice
                  }
                  service={service}
                  vehicleQuantity={vehicleQuantity}
                />
                {service.enableTextInput &&
                  formik.values[service.id + "Enabled"] && (
                    <>
                      <Grid item>
                        <TextField
                          name={service.id + "Details"}
                          label={service.inputLabel}
                          size="small"
                          value={formik.values[service.id + "Details"] || ""}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                {service.category === "deductible" &&
                  service.groupParentId &&
                  formik.values[service.id + "Enabled"] && (
                    <>
                      <Grid item>
                        <TextField
                          name={service.id + "Deductible"}
                          label="Omavastuuosuus"
                          placeholder="Omavastuuosuus"
                          size="small"
                          value={
                            formik.values[service.id + "Deductible"] ||
                            centsToLocalString(
                              offerPrice?.additionalServicePrices[service.id]
                                .deductibleValue || ""
                            )
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getCurrencyUnit()}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  )}
              </Grid>
            );
          })
        ) : (
          <Typography>Tuotteella ei lisättyjä palveluita</Typography>
        )}
      </Grid>
    </FormGrid>
  );
}
