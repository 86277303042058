import { useState } from "react";
import {
  CircularProgress,
  Stack,
  Typography,
  Avatar,
  ListItemAvatar,
  IconButton,
  TextField,
  ListItemText,
  ListItem,
  Divider,
  InputAdornment,
} from "@mui/material";
import { Close, Edit, Euro } from "@mui/icons-material";
import { useField, useFormikContext } from "formik";
import { QuantitySelector } from "../../Reservation/common/utils";
import { useVehicleImage } from "../../../hooks/useVehicleImage";
import { centsToLocalString } from "../../../shared/money";
import { useGetOfferPrices } from "~/hooks/useGetOfferPrices";
export function ProductCardTemplateMobile(props) {
  const { item } = props;
  const { image, icon } = useVehicleImage(item);
  const [, meta, helpers] = useField("reservationVehicles");
  const { value } = meta;
  const { setValue } = helpers;
  const handleDelete = async () => {
    await setValue(value.filter((vehicle) => vehicle.id !== item.id));
  };
  return (
    <>
      <ListItem
        secondaryAction={
          item?.enableQuantity ? (
            <QuantitySelector visibility={true} quantity={1} />
          ) : (
            <Stack direction={"row"}>
              <IconButton>
                <Edit />
              </IconButton>
              <IconButton onClick={handleDelete}>
                <Close />
              </IconButton>
            </Stack>
          )
        }
        disablePadding
        disableGutters
      >
        <ListItemAvatar
          sx={{ height: "60px", width: "60px", marginRight: "5px" }}
        >
          <Avatar
            variant="rounded"
            sx={{ height: "60px", width: "60px" }}
            src={image}
          >
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={<Typography noWrap>{item?.name}</Typography>}
          secondary={
            <Stack>
              <Typography variant="body2">{item?.registrationPlate}</Typography>
              <ListingPrice vehicleId={item?.id} type="price" />
            </Stack>
          }
        />
      </ListItem>
      <ListItem disablePadding disableGutters>
        <FieldInput item={item} />
      </ListItem>
      <Divider sx={{ paddingY: "10px" }} variant="middle" />
    </>
  );
}

function FieldInput(props) {
  const { item, offerPricesLoading } = props;

  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"center"}
      justifyContent={"flex-end"}
      flex={1}
    >
      <DeductibleField vehicleId={item?.id} />
      <PriceField
        vehicleId={item?.id}
        item={item}
        offerPricesLoading={offerPricesLoading}
      />
    </Stack>
  );
}

function PriceField(props) {
  const { vehicleId, item } = props;
  const { values, setFieldValue } = useFormikContext();
  const [fieldInput, setFieldInput] = useState(() => item?.price ?? "");

  const handleChangePrice = () => {
    if (fieldInput === "" || fieldInput === null) {
      return setFieldValue(
        "reservationVehicles",
        values?.reservationVehicles.map((vehicle) => {
          if (vehicle.id === vehicleId) {
            return {
              ...vehicle,
              price: null,
            };
          }
          return vehicle;
        })
      );
    }
    if (fieldInput === item?.price) {
      return;
    }

    return setFieldValue(
      "reservationVehicles",
      values?.reservationVehicles.map((vehicle) => {
        if (vehicle.id === vehicleId) {
          return {
            ...vehicle,
            price: fieldInput,
          };
        }
        return vehicle;
      })
    );
  };

  return (
    <TextField
      variant="standard"
      value={fieldInput}
      onChange={(e) => setFieldInput(e.target.value)}
      onBlur={handleChangePrice}
      label="Hinta"
      sx={{ width: "100px" }}
      inputProps={{
        min: 0,
        style: { textAlign: "right", marginRight: "3px" },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Euro
              sx={{
                color: "grey",
                marginBottom: "5px",
                fontSize: "1.2rem",
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}

function ListingPrice(props) {
  const { loading, vehicleId, type } = props;

  const { values } = useFormikContext();
  const { offerPrices, offerPriceByCategory, offerPricesLoading } =
    useGetOfferPrices(values);

  const priceByCategory = offerPriceByCategory
    ? offerPriceByCategory[type]
    : null;
  const priceByVehicle =
    (!offerPricesLoading &&
      offerPrices?.find((offer) => offer.id === vehicleId)?.price) ??
    null;
  const itemPrice = priceByCategory ?? priceByVehicle ?? null;
  return (
    <Typography variant="caption" sx={{ marginBottom: "5px" }}>
      Listahinta:{" "}
      {loading ? (
        <CircularProgress size={14} />
      ) : (
        `${centsToLocalString(itemPrice, true)}`
      )}
    </Typography>
  );
}

function DeductibleField(props) {
  const { vehicleId, deductiblePrice } = props;
  const { values, setFieldValue } = useFormikContext();
  const storedValue = values?.reservationVehicles.find(
    (vehicle) => vehicle.id === vehicleId
  )?.deductible;
  const [fieldInput, setFieldInput] = useState(() => {
    return storedValue ? centsToLocalString(storedValue) : "";
  });

  const handleChangePrice = (event) => {
    const storedValue = values?.reservationVehicles.find(
      (vehicle) => vehicle.id === vehicleId
    )?.price;
    if (fieldInput === "" || fieldInput === null) {
      return setFieldValue(
        "reservationVehicles",
        values?.reservationVehicles.map((vehicle) => {
          if (vehicle.id === vehicleId) {
            return {
              ...vehicle,
              deductible: deductiblePrice,
            };
          }
          return vehicle;
        })
      );
    }
    if (fieldInput === storedValue) {
      return;
    }

    return setFieldValue(
      "reservationVehicles",
      values?.reservationVehicles.map((vehicle) => {
        if (vehicle.id === vehicleId) {
          return {
            ...vehicle,
            deductible: fieldInput,
          };
        }
        return vehicle;
      })
    );
  };

  return (
    <Stack>
      <TextField
        variant="standard"
        label="Omavastuu"
        placeholder={`${centsToLocalString(deductiblePrice)}`}
        value={fieldInput}
        onChange={(e) => setFieldInput(e.target.value)}
        onBlur={handleChangePrice}
        sx={{ width: "100px" }}
        inputProps={{
          min: 0,
          style: { textAlign: "right", marginRight: "3px" },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Euro
                sx={{
                  color: "grey",
                  marginBottom: "5px",
                  fontSize: "1.2rem",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
