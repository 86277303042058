import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import DialogComponent from "../../../DialogComponent";
import InvoiceItemsTable from "./InvoiceItemsTable";
import { useFormikContext } from "formik";
import { useReservationContext } from "../../ReservationContext";
import { useNotification } from "../../../Notification";
import { AppContext } from "../../../../AppContext";
import { API, graphqlOperation } from "aws-amplify";
import { createPaymentEvent } from "../../../../graphql/mutations";
import InnovoiceCreditDialog from "./InnovoiceCreditDialog";
import { getCurrencyUnit, parseToCents } from "../../../../shared/money";
import PaymentEventsTable from "./PaymentEventsTable";

const ActionButtons = ({
  setShowInvoiceDialog,
  FEATURE_INVOICING,
  invoiceItems,
  invoiceItemsSum,
  unpaidAmount,
  showActions,
  invoicesMutate,
  invoicesLoading,
  paymentEventsMutate,
  paymentEventsLoading,
  combinedEvents,
}) => {
  const { user } = useContext(AppContext);
  const { values } = useFormikContext();
  const { reservationData } = useReservationContext();
  const notification = useNotification();

  const { business } = useContext(AppContext);

  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [showInnovoiceCreditDialog, setShowInnovoiceCreditDialog] =
    useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRowClick = (amount) => {
    setPaymentAmount((amount / 100).toFixed(2).replace(".", ","));
  };

  const handlePaymentDialogOpen = () => {
    // If unpaid less than zero, default to zero
    setPaymentAmount(
      unpaidAmount < 0
        ? (0).toFixed(2).replace(".", ",")
        : (unpaidAmount / 100).toFixed(2).replace(".", ",")
    );
    setShowPaymentDialog(true);
  };

  const handlePaymentDialogClose = () => {
    setPaymentAmount("");
    setShowPaymentDialog(false);
  };

  const handleInnovoiceCreditDialogOpen = () => {
    setShowInnovoiceCreditDialog(true);
  };

  const handleInnovoiceCreditDialogClose = () => {
    setShowInnovoiceCreditDialog(false);
  };

  const handleAcceptPayment = async () => {
    setLoading(true);
    const cents = parseToCents(paymentAmount);
    if (cents === null || !paymentAmount.includes(",")) {
      setPaymentError("Anna hinta muodossa '123,45'", "error");
      setLoading(false);
      return;
    } else {
      setPaymentError("");
    }

    let paymentEvent = {
      reservationId: reservationData.id,
      organizationId: user.organizationId,
      channel: "MANUAL",
      paymentTotal: cents,
      createdByUserID: user.id,
      createdByUserName: user.name,
    };

    switch (user.role) {
      case "ORGANIZATION_ADMIN":
        paymentEvent.orgBusinessId = business.id;
        break;
      case "BUSINESS_ADMIN":
        paymentEvent.orgBusinessId = user.orgBusinessId;
        break;
      case "EXTERNAL_BUSINESS_ADMIN":
        paymentEvent.externalBusinessId = user.externalBusinessId;
        break;
      default:
        console.error("Unsupported role for payment processing.");
        setLoading(false);
        return;
    }

    try {
      await API.graphql(
        graphqlOperation(createPaymentEvent, { input: paymentEvent })
      );
      await paymentEventsMutate();
      notification.show("Maksutapahtuma onnistui.", "success");
    } catch (error) {
      console.error("Error creating payment event:", error);
      notification.show(
        "Virhe luotaessa maksutapahtumaa: " + error.message,
        "error"
      );
    } finally {
      handlePaymentDialogClose();
      setLoading(false);
    }
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        sx={{ mt: 2, mb: 4 }}
      >
        <Button
          variant="contained"
          color="success"
          onClick={handlePaymentDialogOpen}
          disabled={
            reservationData?.type === "INSURANCE" || !reservationData?.id
          }
        >
          Vastaanota maksu
        </Button>
        {business?.id && (
          <Button
            variant="contained"
            color="info"
            onClick={handleInnovoiceCreditDialogOpen}
          >
            Rahoituskysely
          </Button>
        )}
        <Button
          variant="contained"
          onClick={(e) => {
            setShowInvoiceDialog({ type: "FULL" });
          }}
          disabled={
            reservationData?.type === "INSURANCE" || !reservationData?.id
          }
        >
          Lasku asiakkaalle
        </Button>
        <Button
          variant="contained"
          onClick={(e) => {
            setShowInvoiceDialog({ type: "EXTRA" });
          }}
          disabled={!reservationData?.id}
        >
          Lisälaskutus
        </Button>
        <Button
          variant="contained"
          onClick={(e) => {
            if (!values.insuranceCompany) {
              return notification.show("Valitse ensin vakuutusyhtiö");
            }
            if (!values.insuranceData?.billingPermitCode) {
              return notification.show("Aseta lasutuslupatunnus");
            }
            setShowInvoiceDialog({ type: "INSURANCE_COMPANY" });
          }}
          disabled={reservationData?.type !== "INSURANCE"}
        >
          Lasku vakuutusyhtiölle
        </Button>
      </Stack>
      <DialogComponent
        open={showPaymentDialog}
        dialogClose={handlePaymentDialogClose}
        fullWidth
        maxWidth="md"
      >
        <Box padding={4}>
          <Grid container spacing={2} alignItems="center" direction="column">
            <Grid item>
              <InvoiceItemsTable
                invoiceItems={invoiceItems}
                invoiceItemsSum={invoiceItemsSum}
                onRowClick={handleRowClick}
              />
              <PaymentEventsTable
                setShowInvoiceDialog={setShowInvoiceDialog}
                unpaidAmount={unpaidAmount}
                showActions={false}
                invoicesMutate={invoicesMutate}
                invoicesLoading={invoicesLoading}
                paymentEventsMutate={paymentEventsMutate}
                paymentEventsLoading={paymentEventsLoading}
                combinedEvents={combinedEvents}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                Maksun summa
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                placeholder="Syötä summa"
                error={Boolean(paymentError)}
                helperText={paymentError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencyUnit()}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleAcceptPayment}
                    color={"success"}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Vastaanota maksu"
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handlePaymentDialogClose}
                    color="error"
                  >
                    Peruuta
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogComponent>
      <InnovoiceCreditDialog
        open={showInnovoiceCreditDialog}
        onClose={handleInnovoiceCreditDialogClose}
        orgBusinessId={business?.id}
        initialSsn=""
      />
    </>
  );
};

export default ActionButtons;
