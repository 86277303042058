import React, { useState, useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import ProductImage from "../../../ProductImage";
import { AppContext } from "../../../../AppContext";
import ReservationPriceField from "../../common/ReservationPriceField";
import { useFormikContext } from "formik";
import TruncatedTypography from "../../../TruncatedTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import { centsToLocalString } from "../../../../shared/money";
import { useReservationContext } from "../../ReservationContext";
import { QuantitySelector, getEffectiveOfferPrice } from "../../common/utils";
import DeductiblePriceField from "./DeductiblePriceField";

export default function ReservedProductCard(props) {
  const { offer } = props;
  const { mobileViewPort } = useContext(AppContext);
  const {
    modifyOfferPrice,
    modifyOfferQuantity,
    removeOffer,
    vehicleIds,
    disableCategoryDefaultsStacking,
  } = useReservationContext();
  const { setFieldValue } = useFormikContext();
  const [expandedView, setExpandedView] = useState(false);
  const theme = useTheme();

  return (
    <Accordion
      sx={{ width: "100%", boxShadow: theme.shadows[6] }}
      onChange={(e, expanded) => {
        setExpandedView(expanded);
      }}
      square
    >
      <AccordionSummary>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            width={"100%"}
          >
            <ProductImage
              vehicleId={offer?.id}
              imageSize={mobileViewPort ? "large" : undefined}
              size={mobileViewPort ? 72 : 72}
            />

            <Stack direction={mobileViewPort ? "column" : "row"} width={"100%"}>
              <Stack direction={"column"} width={"100%"}>
                <TruncatedTypography text={offer?.name} />
                <Typography variant="caption" sx={{ color: "#a9a9a9" }}>
                  {offer?.registrationPlate ?? ""}
                </Typography>
              </Stack>
              {!expandedView && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography
                    sx={{ fontFamily: "Sofia Pro" }}
                    variant="h6"
                    noWrap
                  >
                    {centsToLocalString(getEffectiveOfferPrice(offer), true)}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {expandedView && (
              <IconButton
                onClick={() => {
                  removeOffer(offer?.id);
                  setFieldValue(
                    "vehicleIds",
                    vehicleIds.filter((id) => id !== offer.id)
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} alignItems={"flex-end"} justifyContent={"flex-end"}>
          <Stack direction={"row"} alignItems={"flex-end"}>
            <QuantitySelector
              visibility={!disableCategoryDefaultsStacking}
              quantityModifier={modifyOfferQuantity}
              quantity={offer?.quantity}
              maxQuantity={offer?.maxQuantity}
              targetId={offer?.id}
            />
            <Box maxWidth={"180px"}>
              <ReservationPriceField
                offer={offer}
                name={"vehiclePrice-" + offer?.id}
                //  isDefaultPriceLoading={defaultPrice === undefined}
                size="small"
                key={offer?.id}
                enableQuantity={!!offer?.enableQuantity}
                vehicleQuantity={offer?.quantity}
                priceModifier={modifyOfferPrice}
              />
            </Box>
          </Stack>
          <Box maxWidth={"180px"}>
            <DeductiblePriceField offer={offer} />
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
