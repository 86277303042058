import React, { useCallback, useContext, useMemo } from "react";

import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Snackbar,
  Select,
  Stack,
  Tooltip,
  MenuItem,
  Typography,
  ListSubheader,
  ListItemText,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataGridPro, useGridApiRef, GridToolbar } from "@mui/x-data-grid-pro";

import { centsToLocalString } from "../../../shared/money";

import { Info } from "@mui/icons-material";

import { AppContext } from "../../../AppContext";
import { useFormikContext } from "formik";
import { useGetOfferData } from "../useGetOfferData";
import { useReservationContext } from "../ReservationContext";
import { useCategories } from "~/queries/useCategories";
import { FormGrid } from "../../common/FormComponents";
import { useFetchVehicles } from "../../../services/fetchVehicles";
import { useFetchCompanies } from "../../../services/fetchCompanies";
import VehicleImage from "~/components/VehicleImage";

export default function ReservationProducts({ offersData, offersDataLoading }) {
  const { USER_ADMIN_CORPORATE } = useContext(AppContext);

  // Determine whether the vehicle has been retrieved or returned
  // AKA FEATURE_SCANDIA_RENT
  /*   const FEATURE_VEHICLE_AVAILABILITY = useMemo(() => {
    return devStaging(process.env.REACT_APP_ENV, company);
  }, [company]);
 */
  const { values } = useFormikContext();

  if (!USER_ADMIN_CORPORATE && !values?.enableWildCard)
    return (
      <ProductListings
        offersData={offersData}
        offersDataLoading={offersDataLoading}
      />
    );
}

function ProductListings({ offersData, offersDataLoading }) {
  const { mobileViewPort, user, group, business } = useContext(AppContext);
  const {
    addNewOffer,
    disableCategoryDefaultsStacking,
    setDisableCategoryDefaultsStacking,
    reservationData,
    modifyOfferPrice,
    vehicleIds,
    setVehicleIds,
  } = useReservationContext();
  const { companies } = useFetchCompanies(group, business?.id);
  const { vehicleData: allVehicles } = useFetchVehicles();
  const { setFieldValue } = useFormikContext();
  const apiRef = useGridApiRef();
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const categories = useCategories();
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    company: user?.organizationId ? false : true,
    vehicleName: mobileViewPort ? false : true,
  });

  const columns = useMemo(() => {
    return [
      vehicleField(disableCategoryDefaultsStacking),
      registrationPlateField,
      categoryField,
      companyField(companies),
      priceField(vehicleIds),
      listingPriceField(offersDataLoading, vehicleIds),
    ];
  }, [
    companies,
    disableCategoryDefaultsStacking,
    offersDataLoading,
    vehicleIds,
  ]);

  const vehiclesByCategory = useMemo(() => {
    const availableCategories = categories?.filter((c) =>
      offersData.find((vehicle) => vehicle?.category?.id === c.id)
    );
    const vehicles = [];

    for (let item of availableCategories) {
      vehicles.push({ categoryTitle: item.name });
      const filteredVehicles = offersData.filter(
        (vehicle) => vehicle?.category?.id === item.id
      );
      for (let vehicle of filteredVehicles) {
        vehicles.push(vehicle);
      }
    }

    return vehicles;
  }, [offersData, categories]);
  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const useUpdateOfferPrice = () => {
    return React.useCallback(
      (offer) =>
        new Promise((resolve, reject) => {
          if (!offer?.modifiedPrice) {
            reject(new Error("Virhe tallennettaessa hintaa."));
          } else {
            modifyOfferPrice(offer.id, offer?.modifiedPrice);
            resolve({ ...offer, modifiedPrice: offer.modifiedPrice });
          }
        }),
      []
    );
  };
  const mutateRow = useUpdateOfferPrice();
  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const response = await mutateRow(newRow);
      setSnackbar({
        children: `${newRow?.name} hinta on muokattu`,
        severity: "success",
      });
      return response;
    },
    [mutateRow]
  );
  const handleChange = (event) => {
    const newValue = event.target.value;
    setVehicleIds(newValue);

    const offers = vehiclesByCategory.filter((offer) =>
      newValue.includes(offer.id)
    );
    addNewOffer(offers);
  };
  if (mobileViewPort) {
    return (
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="select-franchising-label">Valitse auto</InputLabel>

          <Select
            label="Valitse auto"
            fullWidth
            value={vehicleIds}
            multiple
            MenuProps={{ style: { maxHeight: "350px" } }}
            renderValue={(selected) => {
              return selected.map((item, index) => {
                const itemDataReservation =
                  reservationData?.reservationVehicles?.length > 0
                    ? reservationData?.reservationVehicles.find(
                        (offer) => offer.id === item
                      )
                    : null;
                const itemDataOffers = vehiclesByCategory.find(
                  (offer) => offer.id === item
                );
                const itemData = itemDataReservation ?? itemDataOffers;
                const price =
                  itemData?.price ?? // price from reservationData
                  itemData?.modifiedPrice ?? // price modified by user
                  itemData?.listingPrice; // default listing price
                return (
                  <Stack
                    key={item.id ?? `back-up-list-item-key-${index}`}
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom:
                        index !== selected.length - 1 &&
                        "1px solid rgba(0,0,0,0.15)",
                    }}
                    direction={"row"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          maxWidth: "190px",
                          flexDirection: "column",
                        }}
                      >
                        <Typography noWrap>{itemData?.name}</Typography>
                        <Typography sx={{ fontWeight: 550 }}>
                          {itemData?.registrationPlate}
                        </Typography>
                      </Box>
                    </Box>

                    <Typography
                      sx={{ fontFamily: "Sofia Pro", fontWeight: 550 }}
                    >
                      {centsToLocalString(price, true)}
                    </Typography>
                  </Stack>
                );
              });
            }}
            onChange={handleChange}
          >
            {vehiclesByCategory.map((item, index) => {
              if (item?.categoryTitle) {
                return (
                  <ListSubheader
                    key={
                      item?.categoryTitle ?? `back-up-list-item-key-${index}`
                    }
                  >
                    <ListItemText> {item?.categoryTitle} </ListItemText>
                  </ListSubheader>
                );
              } else {
                const selected = vehicleIds
                  ? vehicleIds?.includes(item.id)
                  : false;

                return (
                  <MenuItem
                    key={item.id}
                    sx={{
                      borderBottom: "1px solid rgba(0,0,0,0.15)",
                      bgcolor: selected ? "rgba(0,0,0,0.25)" : undefined,
                    }}
                    value={item.id}
                  >
                    <Stack
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      direction={"row"}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <VehicleImage
                          vehicle={item}
                          variant="rounded"
                          sx={{ marginRight: "5px" }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            maxWidth: "190px",
                            flexDirection: "column",
                          }}
                        >
                          <Typography noWrap>{item?.name}</Typography>
                          <Typography sx={{ fontWeight: 550 }}>
                            {item?.registrationPlate}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography
                        sx={{ fontFamily: "Sofia Pro", fontWeight: 550 }}
                      >
                        {centsToLocalString(
                          item?.price ?? item?.listingPrice,
                          true
                        )}
                      </Typography>
                    </Stack>
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  if (!mobileViewPort) {
    return (
      <Stack
        sx={{
          width: "100%",
          alignItems: "center",
        }}
        data-cy="newReservationDialogProductList"
        direction={"column"}
      >
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            overflow: "scroll",
            marginTop: "15px",
            bgcolor: "rgba(0,0,0,0.005)",
            borderRadius: "14px",
            border: "1px solid rgba(0,0,0,0.10)",
            position: "relative",
            // boxShadow: theme.shadows[4],
          }}
        >
          <DataGridPro
            //    paginationMode="server"
            // components={{ Toolbar: GridToolbar }}
            apiRef={apiRef}
            checkboxSelection
            columns={columns}
            /*          columnVisibilityModel={{
            category: disableCategoryDefaultsStacking,
            registrationPlate: disableCategoryDefaultsStacking,
          }} */
            disableRowSelectionOnClick
            editable={true} // Enable cell editing
            experimentalFeatures={{ newEditingApi: true }}
            isRowSelectable={(params) =>
              checkSelectability(
                params.row.company,
                params.row.id,
                vehicleIds,
                allVehicles
              )
            }
            keepNonExistentRowsSelected
            loading={offersDataLoading}
            slots={{ toolbar: GridToolbar }}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            onRowSelectionModelChange={(selection) => {
              setFieldValue("vehicleIds", selection);
              const selectedIDs = new Set(selection);
              const selectedRowData = offersData.filter((row) =>
                selectedIDs.has(row.id.toString())
              );
              addNewOffer(selectedRowData);
              setVehicleIds(selection);
            }}
            pageSize={20}
            pagination
            processRowUpdate={processRowUpdate}
            rows={offersData}
            rowSelectionModel={vehicleIds}
            rowsPerPageOptions={[2]}
            sx={dataGridStyles}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </Grid>
        {!reservationData && (
          <Box sx={{ display: "flex", width: "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={offersDataLoading}
                  name="disableCategoryDefaultsStacking"
                  checked={disableCategoryDefaultsStacking ? false : true}
                  color="primary"
                  onChange={async () => {
                    await setDisableCategoryDefaultsStacking(
                      (prevState) => !prevState
                    );
                  }}
                />
              }
              label={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Käytä tuotteiden niputusta haussa </Typography>
                  <Tooltip title="Hyödynnä kategoria niputusta haussa. Tuotelistasta valitaan kategorialta kappalemäärä yksittäisen tuotteen sijaan, jos tuotteiden niputus on käytössä.">
                    <Info
                      sx={{ fontSize: "19px", marginLeft: "5px" }}
                      color="info"
                    />
                  </Tooltip>
                </div>
              }
            />
          </Box>
        )}
      </Stack>
    );
  }
}

const dataGridStyles = {
  height: "400px",
  borderColor: "transparent",
  fontFamily: "Public Sans, sans-serif",
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
  },
  "& .MuiDataGrid-columnHeadersInner": {
    backgroundColor: "#f7f7f8",
    color: "#4e536c",
    fontFamily: "Sofia Pro",
    fontSize: "16px",
  },
};

function checkSelectability(companyId, vehicleId, vehicleIds, allVehicles) {
  if (!allVehicles) return null;
  if (vehicleIds?.length > 0) {
    if (vehicleIds.includes(vehicleId)) {
      return true;
    }
    const firstPickCompanyId = allVehicles.find(
      (vehicle) => vehicle.id === vehicleIds[0]
    )?.companyId;
    if (firstPickCompanyId === companyId) {
      return true;
    }
    return false;
  }
  return true;
}
const registrationPlateField = {
  field: "registrationPlate",
  headerName: "Rekisterinumero",
  sortable: true,
  minWidth: 110,

  flex: 0.5,
  renderCell: (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          aligItems: "center",
          width: "100%",
        }}
      >
        {params.value}
      </Box>
    );
  },
};

const priceField = (vehicleIds) => {
  return {
    field: "modifiedPrice",
    headerName: "Hinta",
    sortable: true,
    editable: true,
    minWidth: 120,
    maxWidth: 200,
    flex: 1,
    type: "number",
    renderCell: (params) => {
      if (vehicleIds.includes(params.row.id) && !params?.value) {
        if (!isNaN(params.row.listingPrice))
          return `${centsToLocalString(params.row.listingPrice, true)}`;
      }
    },
  };
};

const listingPriceField = (offerPricesLoading, vehicleIds) => {
  return {
    field: "listingPrice",
    headerName: "Listahinta",
    sortable: true,
    minWidth: 120,
    maxWidth: 200,
    flex: 1,
    type: "number",
    renderCell: (params) => {
      if (offerPricesLoading && vehicleIds.includes(params.id)) {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-end",
            }}
          >
            <CircularProgress size={20} />
          </Box>
        );
      } else {
        if (isNaN(params?.value)) {
          return "";
        } else {
          return `${centsToLocalString(params.value, true)}`;
        }
      }
    },
  };
};

const companyField = (companies) => {
  return {
    field: "companyId",
    headerName: "Toimipiste",
    sortable: true,
    minWidth: 200,
    flex: 1,
    renderCell: (params) => {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography sx={{ fontSize: "16px" }}>
            {companies && companies.find((c) => c?.id === params.value)?.name}
          </Typography>
        </Box>
      );
    },
  };
};

const categoryField = {
  field: "categoryName",
  headerName: "Kategoria",
  sortable: true,
  minWidth: 120,
  flex: 0.5,
};

const vehicleField = (disableCategoryDefaultsStacking) => {
  return {
    field: "vehicleName",
    headerName: "Tuote",
    sortable: true,
    hideable: true,
    minWidth: 250,
    flex: 1,
    renderCell: (params) => {
      //    const isBundle = params.value?.availableInCategory > 0 ? true : false;
      // const titleByProductName = params?.value?.name;
      //   const titleByCategory = `${params.value?.category?.name} - tuote `;
      const vehicleName = params?.value ?? "Poistettu tuote";
      if (disableCategoryDefaultsStacking)
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            {/*      <ProductImage
            image={params.value?.vehicle?.image}
            vehicleId={params.value?.id}
          /> */}
            <Typography sx={{ marginLeft: "15px" }}>
              {/*    {isBundle ? titleByCategory : titleByProductName} */}
              {vehicleName}
            </Typography>
          </Box>
        );
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Typography sx={{ marginLeft: "15px" }}>
            {/*    {isBundle ? titleByCategory : titleByProductName} */}
            {params.row?.name} - tai vastavaa
          </Typography>
        </Box>
      );
    },
  };
};
